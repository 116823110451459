import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { v4 as uuidv4 } from "uuid";

export const initialState = {
  canvas: null,
  pages: [],
  img: [],
  clipArea: null,
  pageSelected: { ref: "" },
};
const addPage = (state, action) => {
  // generate random number as Id
  let randId = uuidv4();
  return updateObject(state, {
    pages: [
      ...state.pages,
      {
        id: randId,
        name: "Enter Name",
        layer_on_top: false,
        file: "",
        status: "active",
        clip: {
          position: {},
          size: {},
        },
        ref: null,
      },
    ],
  });
};
const addOrUpdatePage = (state, action) => {
  if (state.pages && state.pages.find((f) => f.id === action.payload.id)) {
    // page exists, update page
    let index = state.pages.findIndex((f) => f.id === action.payload.id);
    return updateObject(state, {
      pages: [
        ...state.pages.slice(0, index),
        {
          ...action.payload,
        },
        ...state.pages.slice(index + 1),
      ],
    });
  } else {
    // page doesn't exist, create it
    return updateObject(state, {
      pages: [...state.pages, action.payload],
    });
  }
};
const deletePage = (state, action) => {
  let index = state.pages.findIndex((f) => f.id === action.payload.id);
  if (index >= 0) {
    // page exists, delete page
    return updateObject(state, {
      pages: [...state.pages.slice(0, index), ...state.pages.slice(index + 1)],
    });
  }
};
const setCanvas = (state, action) => {
  return updateObject(state, {
    canvas: action.payload,
  });
};
const setClipArea = (state, action) => {
  return updateObject(state, {
    // TODO
  });
};
const setPageSelected = (state, action) => {
  return updateObject(state, {
    pageSelected: action.payload,
  });
};
const setPages = (state, action) => {
  return updateObject(state, {
    pages: action.payload ? action.payload : [],
  });
};
const updatePage = (state, action) => {
  const index = state.pages.findIndex((f) => f.id === action.payload.id);
  if (index === -1) {
    return state;
  }
  return updateObject(state, {
    pages: [
      ...state.pages.slice(0, index),
      {
        ...action.payload,
      },
      ...state.pages.slice(index + 1),
    ],
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PAGE:
      return updatePage(state, action);
    case actionTypes.UPDATE_PAGE_LAYER_ON_TOP:
      return updatePage(state, action);
    case actionTypes.PAGE_SELECTED:
      return setPageSelected(state, action);
    case actionTypes.DELETE_PAGE:
      return deletePage(state, action);
    case actionTypes.SET_CLIP_AREA:
      return setClipArea(state, action);
    case actionTypes.ADD_PAGE:
      return addPage(state, action);
    case actionTypes.ADD_OR_UPDATE_PAGE:
      return addOrUpdatePage(state, action);
    case actionTypes.SET_PAGES:
      return setPages(state, action);
    case actionTypes.SET_CANVAS:
      return setCanvas(state, action);
    default:
      return state;
  }
};

export default reducer;
