import { useState } from "react";
import { useDispatch } from "react-redux";
import { ChromePicker } from "react-color";
import { setProperty } from "../../../store/actions/image";

const ColorPicker = ({ element }) => {
  const dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState();
  const [color, setColor] = useState({
    rgb: { r: 0, g: 0, b: 0 },
    hsl: { h: 0, s: 0, l: 0, a: 1 },
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (val) => {
    setColor(val);
    dispatch(
      setProperty(
        element.id,
        `rgb(${val.rgb.r},${val.rgb.g},${val.rgb.b}`,
        "fill"
      )
    );
  };
  const popover = {
    position: "absolute",
    zIndex: "2",
    right: "20px",
    top: "20px",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  return (
    <div
      className="d-inline-block position-absolute ml-1"
      style={{
        width: "28px",
        height: "28px",
      }}
    >
      <button
        className={""}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          border: "none",
          borderRadius: "5px",
          backgroundColor: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`,
        }}
        onClick={handleClick}
      ></button>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <ChromePicker
            disableAlpha={true}
            color={color ? color.hsl : "ffffff"}
            onChange={(val) => handleChange(val)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
