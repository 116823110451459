import { useDispatch } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { PlusRoundIcon } from "../../icons/Icons";

// Layer list view new layer element for admin view
const NewLayer = () => {
  const dispatch = useDispatch();

  const addLayer = () => {
    dispatch({
      type: actionTypes.ADD_PAGE,
    });
  };
  return (
    <div
      className="mm-pod-layer-element mm-pod-layer-element-primary mb-2"
      onClick={addLayer}
    >
      <div className="mm-pod-layer-element-header">
        <div className="mm-pod-layer-element-icon">
          <PlusRoundIcon />
        </div>
        <div className="mm-pod-layer-element-hgroup">
          <div className="mm-pod-layer-element-title">Add view</div>
          <div className="mm-pod-layer-element-help">Add new view layer ex. front/back/side</div>
        </div>
      </div>
    </div>
  );
};

export default NewLayer;
