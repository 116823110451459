// Slider styles
// Track style
export const TrackStyle = { backgroundColor: "#3767F6" };
// Rail style
export const RailStyle = { backgroundColor: "#DDDDDD" };
// Attribute slider handle style
export const AttributeHandle = {
  borderColor: "white",
  backgroundColor: "#3767F6",
  borderWidth: "3px",
};
// Zoom slider styles
export const ZoomHandlerStyle = {
  borderColor: "transparent",
  height: 40,
  width: 40,
  marginLeft: 0,
  marginTop: -18,
  backgroundColor: "#3767F6",
};
export const ZoomTrackStyle = {
  backgroundColor: "rgba(33, 75, 199, 0.2)",
  height: 40,
  marginTop: -18,
  borderTopLeftRadius: "24px",
  borderBottomLeftRadius: "24px",
};
export const ZoomRailStyle = { opacity: 0, height: "40px", marginTop: -18 };
// Wrapper for numbered layer icon in admin mode
export const NumberedLayerIconWrapper = {
  position: "absolute",
  top: "0%",
  left: "0%",
  bottom: "0%",
  right: "0%",
};
// Child number for layer icon in admin mode
export const NumberedLayerIcon = {
  textAlign: "center",
  height: "100%",
  padding: "7px 0 7px 7px",
};
// Upload template button style in admin mode
export const UploadTemplateStyle = {
  opacity: "0",
  position: "absolute",
  width: "100%",
  height: "100%",
  cursor: "pointer"
};
