import { useSelector } from "react-redux";

// Layer wrapper
const LayerWrapper = ({ el, className, onClick, ...restProps }) => {
    const pageSelected = useSelector((store) => {
      return store.admin ? store.admin.pageSelected : null
  });
  return (
    <div
      className={`mm-pod-layer-element mb-2 ${className !== undefined ? className : ""} ${
        pageSelected && pageSelected.id === el.id
          ? "mm-pod-layer-element--open"
          : ""
      }`}
      onClick={onClick}
    >
      {restProps.children}
    </div>
  );
};

export default LayerWrapper;
