import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getFullMockup } from "../../store/actions/mockups";

// Render mockup large
const MockupView = () => {
  const dispatch = useDispatch();
  const mockupSelected = useSelector(({ image }) => image.mockupSelected);
  const savedMockup = useSelector(({ image }) => {
    let mock = image.mockupFullList.find(
      (f) => f.mockupsNr === mockupSelected.mockupsNr
    );
    if (mock && mock.base64) {
      return mock.base64;
    } else {
      return null;
    }
  });

  useEffect(() => {
    if (!savedMockup && mockupSelected) {
      dispatch(getFullMockup(mockupSelected.mockupsNr));
    }
  }, [savedMockup, mockupSelected]);

  return (
    !!mockupSelected && (
      <img
        className="p-2 text-center mm-pod-mockup"
        src={
          savedMockup
            ? savedMockup
            : "https://static.mediamodifier.com/pod-editor/spinner.gif"
        }
        alt=""
      />
    )
  );
};

export default MockupView;
