import { useSelector } from "react-redux";
import LayerListView from "./layer/LayerListView";
import AdminLayerListView from "./layer/AdminLayerListView";
import SideBarHeader from "../../components/header/SideBarHeader";
import MockupListView from "./mockup/MockupListView";
import { DESIGN_VIEW, MOCKUP_VIEW } from "../../constants/constants";
import AdminFlowControl from "../../components/admin/layer/AdminFlowControl";
import OrderFlowControl from "../../components/layer/OrderFlowControl";

// sidebar containing the controls, image loading, layer ordering
const Sidebar = () => {
  const adminMode = useSelector(({ app }) => app.adminMode);
  const indexSelected = useSelector(({ app }) => app.indexSelected);

  const sideBar = () => {
    switch (indexSelected) {
      case MOCKUP_VIEW:
        return <MockupListView />;
      case DESIGN_VIEW:
        return adminMode ? <AdminLayerListView /> : <LayerListView />;
      default:
        break;
    }
  };

  return (
    <aside className="mm-pod-sidebar">
      <SideBarHeader />
      {sideBar()}
      {adminMode ? <AdminFlowControl /> : <OrderFlowControl />}
    </aside>
  );
};

export default Sidebar;
