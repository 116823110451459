import React from "react";
import { useDispatch } from "react-redux";
import { loadImage } from "../../store/actions/image";
import { TextareaT } from 'react-bootstrap-icons';

// Text layer type to add
const NewTextLayer = () => {
  const dispatch = useDispatch();
  const addText = () => {
    dispatch(loadImage(null, null, "i-text"));
  };
  return (
    <div
      className="mm-pod-layer-element mm-pod-layer-element-primary mb-2"
      onClick={addText}
    >
      <div className="mm-pod-layer-element-header">
        <div className="mm-pod-layer-element-icon">
          <TextareaT size={16} />
        </div>
        <div className="mm-pod-layer-element-hgroup">
          <div className="mm-pod-layer-element-title">Add text</div>
        </div>
      </div>
    </div>
  );
};

export default NewTextLayer;
