export const mm_dpi_300 = 11.81103;
export const mm_to_inch = 0.0393701;
export const allowed_file_types = ".svg,.jpg,.png,.jpeg";
export const attributes = [
  {
    value: "opacity",
    label: "Opacity",
  },
  { value: "lineHeight", label: "Line Height" },
  { value: "fontWeight", label: "Font Weight" },
  { value: "charSpacing", label: "Spacing" },
];
export const style = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      zIndex: 9100011,
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 9100011,
    };
  },
  menuPortal: (styles) => {
    return {
      ...styles,
      zIndex: 9100011,
    };
  },
  indicatorSeparator: (styles) => {
    return {
      ...styles,
      display: "none",
    };
  },
};
export const DESIGN_VIEW = 0;
export const MOCKUP_VIEW = 1;
export const ZOOM_FACTOR = 0.3;

export const fonts = {
  "Roboto": {
      "name": "Roboto",
      "url": "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap",
      "weights": ["100", "300", "400", "700", "900"]
  },
  "Lato": {
      "name": "Lato",
      "url": "https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap",
      "weights": ["100", "300", "400", "700", "900"]
  },
  "Slabo27px": {
      "name": "Slabo 27px",
      "url": "https://fonts.googleapis.com/css?family=Slabo+27px&display=swap",
      "weights": ["400"]
  },
  "ConcertOne": {
      "name": "Concert One",
      "url": "https://fonts.googleapis.com/css?family=Concert+One&display=swap",
      "weights": ["400"]
  },
  "Lora": {
      "name": "Lora",
      "url": "https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i&display=swap",
      "weights": ["400", "700"]
  },
  "Yantramanav": {
      "name": "Yantramanav",
      "url": "https://fonts.googleapis.com/css?family=Yantramanav:100,300,400,500,700,900&display=swap",
      "weights": ["100", "300", "400", "500", "700", "900"]
  },
  "Raleway": {
      "name": "Raleway",
      "url": "https://fonts.googleapis.com/css?family=Raleway:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap",
      "weights": ["100", "300", "400", "700", "900"]
  },
  "DancingScript": {
      "name": "Dancing Script",
      "url": "https://fonts.googleapis.com/css?family=Dancing+Script:400,700&display=swap",
      "weights": ["400", "700"]
  },
  "ShadowsIntoLight": {
      "name": "Shadows Into Light",
      "url": "https://fonts.googleapis.com/css?family=Shadows+Into+Light&display=swap",
      "weights": ["400"]
  },
  "Comfortaa": {"name": "Comfortaa", "weights": ["300", "400", "500", "600", "700"] },
  "Pacifico": {"name": "Pacifico", "weights": ["400"] },
  "AmaticSC": {"name": "Amatic SC", "weights": ["400", "700"] },
  "GreatVibes": {"name": "Great Vibes", "weights": ["400"] },
  "ArchitectsDaughter": {"name": "Architects Daughter", "weights": ["400"] }, 
  "SueEllenFrancisco": {"name": "Sue Ellen Francisco", "weights": ["400"] },
  "Prompt": {"name": "Prompt", "weights": ["100", "200", "300", "400", "500","600", "700", "800", "900"] },
  "Rokkitt": {"name": "Rokkitt", "weights": ["100", "200", "300", "400", "500","600", "700", "800", "900"] },
  "Courgette": {"name": "Courgette", "weights": ["400"] },
  "Alegreya": {"name": "Alegreya", "weights": ["400", "500","600", "700", "800", "900"] },
  "OldStandardTT": {"name": "Old Standard TT", "weights": ["400", "700"] },
  "Cardo": {"name": "Cardo", "weights": ["400", "700"] },
  "ZillaSlab": {"name": "Zilla Slab", "weights": ["300", "400", "500","600", "700"] },
  "PathwayGothicOne": {"name": "Pathway Gothic One", "weights": ["400"] },
  "Cantarell": {"name": "Cantarell", "weights": ["400", "700"] },
  "NanumMyeongjo": {"name": "Nanum Myeongjo", "weights": ["400", "700", "800"] },
  "Montserrat": {"name": "Montserrat", "weights": ["100", "200", "300", "400", "500","600", "700", "800", "900"] },
  "Poppins": {"name": "Poppins", "weights": ["100", "200", "300", "400", "500","600", "700", "800", "900"] },
  "LibreFranklin": {"name": "Libre Franklin", "weights": ["100", "200", "300", "400", "500","600", "700", "800", "900"] },
  "SourceSansPro": {"name": "Source Sans Pro", "weights": ["200", "300", "400", "500","600", "700", "800", "900"] },
  "Muli": {"name": "Muli", "weights": ["200", "300", "400", "500","600", "700", "800", "900"] },
  "Barlow": {"name": "Barlow", "weights": ["100", "200", "300", "400", "500","600", "700", "800", "900"] },
  "Exo": {"name": "Exo", "weights": ["100", "200", "300", "400", "500","600", "700", "800", "900"] },
  "Overpass": {"name": "Overpass", "weights": ["100", "200", "300", "400", "500","600", "700", "800", "900"] },
  "Inconsolata": {"name": "Inconsolata", "weights": ["400", "700"] },
  "SourceCodePro": {"name": "Source Code Pro", "weights": ["200", "300", "400", "500","600", "700", "800", "900"] },
  "Lobster": {"name": "Lobster", "weights": ["400"] },
  "Anton": {"name": "Anton", "weights": ["400"] },
  "Coda": {"name": "Coda", "weights": ["400", "800"] },
  "LeagueScript": {"name": "League Script", "weights": ["400"] },
  "Oswald": {"name": "Oswald", "weights": ["200", "300", "400", "500", "600", "700"] },
} 