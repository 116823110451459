import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fonts } from "../../../../constants/constants";
import { setProperty } from "../../../../store/actions/image";

// font weight hook
const FontWeightHook = (props) => {
  const { element } = props;
  const dispatch = useDispatch();
  const template = useSelector(({ image }) => image.template);
  const pageObjects = useSelector(({ image }) => {
    if (template && template.id) {
      const page = image.pages.find((p) => p.id === template.id);
      if (page && page.json && page.json.objects && page.json.objects.length) {
        return page.json.objects;
      }
      return [];
    } else {
      return [];
    }
  });
  
  const changeFontWeight = (val) => {
    dispatch(setProperty(element.id, val.value, "fontWeight"));
  };
  const item = pageObjects.find((f) => f.id === element.id);

  if (!item) {
    return { onChange: changeFontWeight, value: null, options: null };
  }
  const fontOption = Object.values(fonts).find(
    (value) => value.name === item.fontFamily
  );
  let weightOption = null;
  if (fontOption && fontOption.weights) {
    weightOption = fontOption.weights.map((weight, idx) => {
      return {
        value: weight,
        label: weight,
      };
    });
  } else {
    weightOption = [];
  }
  
  const value = weightOption.find((f) => f.value === item.fontWeight);
  return { onChange: changeFontWeight, value: value, options: weightOption };
};

export default FontWeightHook;
