// app actions
export const UPDATING_JSON = "UPDATING_JSON";
export const JSON_READY = "JSON_READY";
export const INIT_DONE = "INIT_DONE";
export const NAV_CLICKED = "NAV_CLICKED";
export const END_EDIT = "END_EDIT";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const START_SUBMIT_ORDER = "START_SUBMIT_ORDER";
export const SUBMIT_ORDER_FAILED = "SUBMIT_ORDER_FAILED";
export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";

// image actions
export const LOAD_TEMPLATES = "LOAD_TEMPLATES";
export const UPDATE_TEMPLATES = "UPDATE_TEMPLATES";
export const LOAD_MOCKUPS = "LOAD_MOCKUPS";
export const FINISH_DELETE = "FINISH_DELETE";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const DELETE_TEXT = "DELETE_TEXT";
export const SET_ACTIVE_IMAGE = "SET_ACTIVE_IMAGE";
export const SET_CANVAS = "SET_CANVAS";
export const ADD_OR_UPDATE_PAGE = "ADD_OR_UPDATE_PAGE";
export const SET_CLIP_AREA = "SET_CLIP_AREA";
export const SET_SCALE = "SET_SCALE";
export const SET_ZOOM = "SET_ZOOM";
export const LOAD_COLOR = "LOAD_COLOR";

// template actions
export const SET_ACTIVE_TEMPLATE = "SET_ACTIVE_TEMPLATE";
export const CLEAR_TEMPLATE = "CLEAR_TEMPLATE";

// canvas actions
export const INIT_CANVAS = "INIT_CANVAS";
export const REMOVE_IMAGE = "REMOVE_IMAGE";

// Change listeners
export const TEXT_CHANGED = "TEXT_CHANGED";

// toast
export const PPI_ALERT = "PPI_ALERT";
export const RESET_PPI_ALERT = "RESET_PPI_ALERT";

// admin actions
export const ADD_PAGE = "ADD_PAGE";
export const SET_PAGES = "SET_PAGES";
export const DELETE_PAGE = "DELETE_PAGE";
export const PAGE_SELECTED = "PAGE_SELECTED";
export const UPDATE_PAGE = "UPDATE_PAGE";
export const UPDATE_PAGE_LAYER_ON_TOP = "UPDATE_PAGE_LAYER_ON_TOP";

// mockup actions
export const ADD_UPDATE_MOCKUP_THUMBNAIL = "ADD_UPDATE_MOCKUP_THUMBNAIL";
export const ADD_UPDATE_MOCKUP_FULL = "ADD_UPDATE_MOCKUP_FULL";
export const MOCKUP_SELECTED = "MOCKUP_SELECTED";
export const SAVE_FULL_MOCKUP = "SAVE_FULL_MOCKUP";
export const CLEAR_FULL_MOCKUP = "CLEAR_FULL_MOCKUP";
export const CLEAR_THUMB_MOCKUP = "CLEAR_THUMB_MOCKUP";
