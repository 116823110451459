import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";
import { DESIGN_VIEW } from "../../constants/constants";

export const initialState = {
  indexSelected: DESIGN_VIEW,
  defaultContainer: null,
  apiKey: null,
  templateId: null,
  modalIsOpen: true,
  adminMode: false,
  exportReady: true,
  initDone: false,
  previewMode: false,
};
const openModal = (state, action) => {
  return updateObject(state, {
    modalIsOpen: true,
  });
};
const closeModal = (state, action) => {
  return updateObject(state, {
    modalIsOpen: false,
  });
};
const setSelected = (state, action) => {
  return updateObject(state, {
    indexSelected: action.payload,
  });
};
const updateJson = (state, action) => {
  return updateObject(state, {
    exportReady: false,
  });
};
const readyJson = (state, action) => {
  return updateObject(state, {
    exportReady: true,
  });
};
const initDone = (state, action) => {
  return updateObject(state, {
    initDone: true,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_DONE:
      return initDone(state, action);
    case actionTypes.UPDATING_JSON:
      return updateJson(state, action);
    case actionTypes.JSON_READY:
      return readyJson(state, action);
    case actionTypes.OPEN_MODAL:
      return openModal(state, action);
    case actionTypes.CLOSE_MODAL:
      return closeModal(state, action);
    case actionTypes.NAV_CLICKED:
      return setSelected(state, action);
    default:
      return state;
  }
};

export default reducer;
