import { PageIcon } from "../../../components/icons/Icons";
import { Trash, ThreeDotsVertical } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import EditPage from "./editor/EditPage";
import LayerWrapper from "../../layer/LayerWrapper";
import LayerHeader from "../../layer/LayerHeader";
import LayerIcon from "../../layer/LayerIcon";
import LayerActions from "../../layer/LayerActions";
import LayerBody from "../../layer/LayerBody";

// Layer list view element for admin mode
const Layer = ({ el, index }) => {
  const dispatch = useDispatch();
  const pageSelected = useSelector(({ admin }) => admin.pageSelected);
  const selectPage = () => {
    dispatch({ type: actionTypes.PAGE_SELECTED, payload: el });
  };
  const deletePage = (e) => {
    e.stopPropagation();
    dispatch({ type: actionTypes.DELETE_PAGE, payload: { id: el.id } });
  };
  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
    <LayerWrapper el={el} onClick={selectPage}>
      <LayerHeader>
        <LayerIcon>
          <PageIcon></PageIcon>
          <span className="position-absolute">{index}</span>
        </LayerIcon>
        <LayerBody>
          <div className="mm-pod-layer-element-title">{el.name}</div>
          <div className="mm-pod-layer-element-help"></div>
        </LayerBody>
        <LayerActions>
          <button onClick={deletePage} type="button" className="btn btn-icon">
            <Trash size={16} />
          </button>
          <button
            className="btn btn-icon"
            type="button"
            id={`handler-${el.id}`}
            onClick={handleClick}
          >
            <ThreeDotsVertical size={16} />
          </button>
        </LayerActions>
      </LayerHeader>
      {pageSelected && pageSelected.id === el.id ? (
        <div className="mm-pod-layer-element-body">
          <EditPage el={el} />
        </div>
      ) : (
        ""
      )}
    </LayerWrapper>
  );
};

export default Layer;
