import { useSelector, useDispatch } from "react-redux";
import { submitOrder } from "../../store/actions/image";
import { closeModal, setNav } from "../../store/actions/app";
import { DESIGN_VIEW, MOCKUP_VIEW } from "../../constants/constants";

// Flow control buttons - cancel, continue
const OrderFlowControl = () => {
  const dispatch = useDispatch();
  const pages = useSelector(({ image }) => image.pages);
  const indexSelected = useSelector(({ app }) => app.indexSelected);
  const previewMode = useSelector(({ app }) => app.previewMode);
  // close modal if in design view, otherwise navigate back
  const closeOrReturn = () => {
    if (indexSelected === DESIGN_VIEW) {
      dispatch(closeModal());
    } else {
      dispatch(setNav(DESIGN_VIEW));
    }
  };

  // next view or submit
  const onClick = () => {
    if (indexSelected === DESIGN_VIEW) {
      if (
        pages &&
        pages.length &&
        pages.some((p) => p.json && p.json.objects && p.json.objects.length)
      ) {
        dispatch(setNav(MOCKUP_VIEW));
      } else {
        alert("Please don’t leave the product blank. Upload an image, add the text and see the results.");
      }
    } else {
      if (window.confirm("All done and ready for print?")) {
        dispatch(submitOrder());
      }
    }
  };

  return (
    <footer className="mm-pod-sidebar-footer">
      <div className="row mx-n1">
        <div className="col-auto px-1">
          <button className="btn btn-link" onClick={closeOrReturn}>
            {indexSelected === DESIGN_VIEW ? "Cancel" : "Previous"}
          </button>
        </div>
        <div className="col px-1">
          {indexSelected === DESIGN_VIEW && (
            <button className={`btn btn-secondary btn-block`} onClick={onClick}>
              Preview
            </button>
          )}
          {(indexSelected === MOCKUP_VIEW && !previewMode) && (
            <button className={`btn btn-secondary btn-block`} onClick={onClick}>
              I'm done
            </button>
          )}
        </div>
      </div>
    </footer>
  );
};

export default OrderFlowControl;
