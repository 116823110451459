import React from "react";
import { Image as ImageIcon } from 'react-bootstrap-icons';
import { useDispatch } from "react-redux";
import { loadImage } from "../../store/actions/image";
import { allowed_file_types } from "../../constants/constants";

// Image layer type to add
const NewImageLayer = () => {
  const dispatch = useDispatch();
  const loadFile = (e) => {
    var reader = new FileReader();
    reader.onload = function (event) {
      var imgObj = new Image();
      imgObj.src = event.target.result;
      if (e.target.files[0].size > 52428800) {
        alert("Maximum file size exceeded!");
        return;
      }
      const name = e.target.files[0].name;
      dispatch(loadImage(imgObj, name, "img"));
      e.target.value = "";
    };
    if (e.target.files[0]) reader.readAsDataURL(e.target.files[0]);
  };
  return (
    <div className="mm-pod-layer-element mm-pod-layer-element-primary mb-2">
      <div className="mm-pod-layer-element-header">
        <div className="mm-pod-layer-element-icon">
          <ImageIcon size={16} />
        </div>
        <div className="mm-pod-layer-element-hgroup">
          <div className="mm-pod-layer-element-title">Upload Image</div>
          <div className="mm-pod-layer-element-help">Recommended size 3543 x 4008 px</div>
        </div>
        <input
          className=""
          type="file"
          accept={allowed_file_types}
          onChange={(e) => loadFile(e)}
          title="Upload image"
          style={{
            opacity: "0",
            position: "absolute",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </div>
  );
};

export default NewImageLayer;
