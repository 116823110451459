import appReducer from "./reducers/app";
import imgReducer from "./reducers/image";
import adminReducer from "./reducers/admin";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { DESIGN_VIEW } from "../constants/constants";
import axios from "axios";
import * as actionTypes from "./actions/actionTypes";
import { selectMockup } from "./actions/mockups";
import { loadColors } from "./actions/image";
import { initialState as appInitState } from "./reducers/app";
import { initialState as adminInitState } from "./reducers/admin";

// define store as singleton
// store instance
let instance = null;
// custom callbacks
const callback = {
  successCallback: null,
  failCallback: null,
};
class Store {
  constructor(config) {
    instance = this.initStore(config);
    this.initTemplates(config, config.domain, config.apiKey, config.templateId);
    return instance;
  }
  initStore(config) {
    const reducers = {
      app: appReducer,
      image: imgReducer,
    };
    if (config && config.adminMode === true) {
      reducers.admin = adminReducer;
    }
    const rootReducer = combineReducers(reducers);

    callback.successCallback = config.successCallback;
    callback.failCallback = config.failCallback;

    const initialState = {
      app: {
        ...appInitState,
        defaultContainer: config.defaultContainer,
        apiKey: config.apiKey,
        templateId: config.templateId,
        adminMode: config.adminMode === true ? true : false,
        indexSelected: DESIGN_VIEW,
        modalIsOpen: true,
        domain: config.domain,
        exportReady: true,
        initDone: false,
        previewMode: config.previewMode === true ? true : false,
      },
    };
    if (config && config.adminMode === true) {
      initialState.admin = {
        ...adminInitState,
        canvas: null,
        pages: [],
        pageSelected: { ref: "" },
        img: [],
        clipArea: null,
      };
    }
    return createStore(rootReducer, initialState, applyMiddleware(thunk));
  }
  initTemplates(config, domain, apiKey, templateId) {
    axios
      .get(`${domain}/api/templates/${templateId}`, {
        headers: {
          Authorization: `${apiKey}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (config.adminMode === true) {
            instance.dispatch({
              type: actionTypes.SET_PAGES,
              payload: res.data.tabs,
            });
          } else {
            instance.dispatch({
              type: actionTypes.LOAD_TEMPLATES,
              payload: res.data.tabs,
            });
          }
          if (res.data.attributes && res.data.attributes.colors) {
            instance.dispatch(loadColors(res.data.attributes.colors));
          }
          if (res.data.mockups && res.data.mockups.length > 0) {
            const mockups = res.data.mockups.map((m) => {
              return {
                id: m._id,
                viewId: m.viewId,
                mockupsNr: m.mockupsNr,
                mockupsLayerUuId: m.mockupsLayerUuId,
              };
            });
            instance.dispatch({
              type: actionTypes.LOAD_MOCKUPS,
              payload: mockups,
            });
            instance.dispatch(selectMockup(mockups[0]));
          }
          instance.dispatch({ type: actionTypes.INIT_DONE });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  static getStore() {
    return instance;
  }
  static getSuccessCallback() {
    if (callback.successCallback) {
      return callback.successCallback;
    } else {
      return null;
    }
  }
  static getFailCallback() {
    if (callback.failCallback) {
      return callback.failCallback;
    } else {
      return null;
    }
  }
}
export default Store;
