import {
  Trash,
  ThreeDotsVertical,
  Back,
  TextareaT,
} from "react-bootstrap-icons";
import EditTextOptions from "../../components/layer/EditTextOptions";
import PpiAlert from "../../components/layer/PpiAlert";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deleteImage, duplicateImage } from "../../store/actions/image";
import LayerWrapper from "./LayerWrapper";
import LayerHeader from "./LayerHeader"
import LayerIcon from "./LayerIcon"
import LayerActions from "./LayerActions"
import LayerBody from "./LayerBody"

// Layer list view element
const Layer = ({ el }) => {
  const dispatch = useDispatch();
  const activeObj = useSelector(({ image }) => image.activeObj);
  const ppiAlert = useSelector(({ image }) => image.ppiAlert);
  const textChanged = useSelector(({ image }) => image.textChanged);
  const canvas = useSelector(({ image }) => image.canvas);
  const selectObject = () => {
    canvas.setActiveObject(canvas.getObjects().find((f) => f.id === el.id));
    canvas.renderAll();
  };
  const deleteImg = (e) => {
    e.stopPropagation();
    dispatch(deleteImage(el.id));
  };
  const duplicateImg = (e) => {
    e.stopPropagation();
    dispatch(duplicateImage(el.id));
  };
  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
      <LayerWrapper
        el={el}
        className={`${
          ppiAlert &&
          ppiAlert.length > 0 &&
          ppiAlert.findIndex((f) => f.id === el.id) !== -1
            ? " mm-pod-layer-element--error"
            : ""
        }
      `}
        onClick={selectObject}
      >
      <LayerHeader>
        <LayerIcon>
          {el.type === "image" ? (
            <img className="mm-pod-layer-thumb" src={el.src} alt="No data" />
          ) : (
            <TextareaT size={16} />
          )}
        </LayerIcon>
        <LayerBody>
          <div className="mm-pod-layer-element-title">
            {el.type === "i-text"
              ? canvas.getObjects().find((f) => f.id === el.id)
                ? canvas.getObjects().find((f) => f.id === el.id).text
                : ""
              : el.title}
          </div>
          <div className="mm-pod-layer-element-help">
            {el.type === "image" ? "Image" : "Text"}
          </div>
        </LayerBody>
        <LayerActions>
          <button className="btn btn-icon" type="button" onClick={duplicateImg}>
            <Back size={16} />
          </button>
          <button className="btn btn-icon" type="button" onClick={deleteImg}>
            <Trash size={16} />
          </button>
          <button
            className="btn btn-icon"
            type="button"
            id={`handler-${el.id}`}
            onClick={handleClick}
          >
            <ThreeDotsVertical size={16} />
          </button>
        </LayerActions>
      </LayerHeader>
      {activeObj && activeObj.id === el.id && el.type === "i-text" ? (
        <div className="mm-pod-layer-element-body">
          <EditTextOptions element={el} />
        </div>
      ) : (
        ""
      )}
      {ppiAlert &&
      ppiAlert.length > 0 &&
      ppiAlert.findIndex((f) => f.id === el.id) !== -1 ? (
        <div className="mm-pod-layer-element-body">
          <PpiAlert />
        </div>
      ) : (
        ""
      )}
    </LayerWrapper>
  );
};

export default Layer;
