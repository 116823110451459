import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "rc-slider/assets/index.css";
import { setProperty } from "../../../../store/actions/image";
import SliderOptions from "./SliderOptions";

// Text attribute options
const LineHeightOption = ({ element, className }) => {
  const dispatch = useDispatch();
  const template = useSelector(({ image }) => image.template);
  const pageObjects = useSelector(({ image }) => {
    if (template && template.id) {
      const page = image.pages.find((p) => p.id === template.id);
      if (page && page.json && page.json.objects && page.json.objects.length) {
        return page.json.objects;
      }
      return [];
    } else {
      return [];
    }
  });
  const changeValue = (val) => {
    if (isNaN(val)) {
      return;
    }
    const item = pageObjects.find((f) => f.id === element.id);
    if (item) {
      dispatch(setProperty(element.id, val, "lineHeight"));
    }
  };
  const item = pageObjects.find((f) => f.id === element.id);
  const itemValue = item && item["lineHeight"] ? item["lineHeight"] : "";
  return (
    <SliderOptions
      attribute={"lineHeight"}
      changeValue={changeValue}
      value={itemValue}
      className={className}
      step={0.04}
      max={16}
      min={0.04}
    />
  );
};

export default LineHeightOption;
