import * as actionTypes from "../../store/actions/actionTypes";
import { fabric } from "fabric";
import { ZOOM_FACTOR } from "../../constants/constants";

// FabricJS
export const loadTemplateImage = () => (dispatch, getState) => {
  const { pageSelected } = getState().admin;
  const { canvas } = getState().image;
  const loadBackground = (originalImg, options, isSvg) => {
    if (!originalImg) {
      return;
    }
    var img = isSvg
      ? fabric.util.groupSVGElements(originalImg, options)
      : new fabric.Image(originalImg);
    // var img = new fabric.Image(originalImg);
    let longestSide = img.width > img.height ? img.width : img.height;
    img.scale(2000 / longestSide);
    img.excludeFromExport = true;
    // scale all templates to XXX x 2000 px, save factor scaled by and the clip area for this template in the state
    canvas.setHeight((2000 / longestSide) * img.height);
    canvas.setWidth((2000 / longestSide) * img.width);
    canvas.zoomToPoint(
      new fabric.Point(canvas.width / 2, canvas.height / 2),
      ZOOM_FACTOR
    );

    // update state
    dispatch({ type: actionTypes.SET_SCALE, payload: 2000 / longestSide });
    canvas.setBackgroundImage(
      img,
      function () {
        canvas.renderAll.bind(canvas);
      },
      {}
    );
    // add clip box if exists
    if (
      pageSelected.clip &&
      pageSelected.clip.position &&
      pageSelected.clip.position.left &&
      pageSelected.clip.position.top &&
      pageSelected.clip.size &&
      pageSelected.clip.size.width &&
      pageSelected.clip.size.height &&
      pageSelected.clip.size.widthP &&
      pageSelected.clip.size.heightP
    ) {
      const box = new fabric.Rect({
        id: pageSelected.id,
        top: (canvas.height * pageSelected.clip.position.top) / 100,
        left: (canvas.width * pageSelected.clip.position.left) / 100,
        width: (canvas.width * pageSelected.clip.size.widthP) / 100,
        height: (canvas.height * pageSelected.clip.size.heightP) / 100,
        fill: "rgba(0,0,0,0)",
        stroke: "rgba(33, 75, 199, 1)",
        cornerColor: "rgba(33, 75, 199, 1)",
        cornerSize: 15,
        transparentCorners: false,
        strokeWidth: 4,
      });
      box.on("mouseup", function (e) {
        dispatch({
          type: actionTypes.UPDATE_PAGE,
          payload: {
            ...pageSelected,
            clip: {
              type: "rect",
              position: {
                left: (box.left / canvas.width) * 100,
                top: (box.top / canvas.height) * 100,
                unit: "percent",
              },
              size: {
                width: box.width * box.scaleX * 0.08467,
                height: box.height * box.scaleY * 0.08467,
                widthP: ((box.width * box.scaleX) / canvas.width) * 100,
                heightP: ((box.height * box.scaleY) / canvas.height) * 100,
                unit: "mm",
              },
            },
          },
        });
      });
      canvas.add(box);
      canvas.renderAll();
    }
  };
  const isSvg = pageSelected.type === "svg";
  try {
    if (isSvg) {
      fabric.loadSVGFromString(pageSelected.ref, function (img, options) {
        loadBackground(img, options, isSvg);
      });
    } else {
      fabric.util.loadImage(pageSelected.ref, function (img) {
        loadBackground(img, null, isSvg);
      });
    }
  } catch (error) {
    console.error(error)
  }
  
};
export const addViewArea = () => (dispatch, getState) => {
  const { canvas } = getState().image;
  const { pageSelected } = getState().admin;
  const box = new fabric.Rect({
    id: pageSelected.id,
    top: canvas.height / 4,
    left: canvas.width / 4,
    width: canvas.width / 2,
    height: canvas.height / 2,
    fill: "rgba(0,0,0,0)",
    stroke: "rgba(33, 75, 199, 1)",
    cornerColor: "rgba(33, 75, 199, 1)",
    cornerSize: 30,
    transparentCorners: false,
    strokeWidth: 4,
  });
  box.on("mouseup", function (e) {
    dispatch({
      type: actionTypes.UPDATE_PAGE,
      payload: {
        ...pageSelected,
        clip: {
          type: "rect",
          position: {
            left: (box.left / canvas.width) * 100,
            top: (box.top / canvas.height) * 100,
            unit: "percent",
          },
          size: {
            width: box.width * box.scaleX * 0.08467,
            height: box.height * box.scaleY * 0.08467,
            widthP: ((box.width * box.scaleX) / canvas.width) * 100,
            heightP: ((box.height * box.scaleY) / canvas.height) * 100,
            unit: "mm",
          },
        },
      },
    });
  });
  //   canvas.add(box);
  //   canvas.renderAll();
  // update page
  const newPage = {
    ...pageSelected,
    clip: {
      type: "rect",
      position: {
        left: (box.left / canvas.width) * 100,
        top: (box.top / canvas.height) * 100,
        unit: "percent",
      },
      size: {
        width: box.width,
        height: box.height,
        widthP: (box.width / canvas.width) * 100,
        heightP: (box.height / canvas.height) * 100,
        unit: "mm",
      },
    },
  };
  dispatch({
    type: actionTypes.UPDATE_PAGE,
    payload: newPage,
  });
  dispatch({
    type: actionTypes.PAGE_SELECTED,
    payload: newPage,
  });
};
export const changePage = (el) => (dispatch, getState) => {
  const { canvas } = getState().image;
  // clear old page and clip area
  canvas.remove(...canvas.getObjects());
  // set new page
  dispatch({
    type: actionTypes.PAGE_SELECTED,
    payload: JSON.parse(JSON.stringify(el)),
  });
};
