import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
// Page selection items overlaying canvas
const AdminPageSelection = () => {
  const dispatch = useDispatch();
  const pages = useSelector(({ admin }) => admin.pages);
  const pageSelected = useSelector(({ admin }) => admin.pageSelected);

  const changePage = (page) => {
    dispatch({ type: actionTypes.PAGE_SELECTED, payload: page });
  };

  return (
    <div className="mm-pod-main-options">
      <div className="row mx-n1 mm-pod-main-option">
        {pages && pages.length > 0
          ? pages.map((p) => (
              <div className="col-auto px-1 mb-2" key={p.id}>
                <button
                  className={`btn btn-link ${
                    pageSelected.id === p.id ? "btn-link--active" : ""
                  }`}
                  onClick={() => changePage(p)}
                >
                  {p.name}
                </button>
              </div>
            ))
          : ""}
        </div>
    </div>
  );
};

export default AdminPageSelection;
