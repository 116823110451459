import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { selectMockup, getMockupThumbs } from "../../../store/actions/mockups";

// pod editor mockup view
const MockupListView = () => {
  const mockups = useSelector(({ image }) => image.mockups);
  // rendered mockups
  const mockupThumbsList = useSelector(({ image }) => image.mockupThumbsList);
  const mockupSelected = useSelector(({ image }) => image.mockupSelected);
  const dispatch = useDispatch();
  const focusMockup = (mockup) => {
    dispatch(selectMockup(mockup));
  };
  
  useEffect(() => {
    mockups
      .filter(
        (i, index, self) =>
          index === self.findIndex((t) => t.mockupsNr === i.mockupsNr)
      )
      .forEach((f) => {
        dispatch(getMockupThumbs(f.mockupsNr));
      });
  }, [mockups]);

  if (!mockups || mockups.length === 0) {
    return "";
  }
  return (
    <div className="mm-pod-sidebar-body">
      <div className="mm-pod-mockup-thumb-grid">
        {mockups
          .filter(
            (i, index, self) =>
              index === self.findIndex((t) => t.mockupsNr === i.mockupsNr)
          )
          .map((i) => {
            let base64img = "";
            let mockup = mockupThumbsList.find(
              (f) => f.mockupsNr === i.mockupsNr
            );
            if (mockup && mockup.base64) {
              base64img = mockup.base64;
            }
            return (
              <div
                className={`mm-pod-mockup-thumb-item mm-pod-mockup-thumb-item${
                  mockupSelected && i.id === mockupSelected.id
                    ? "--selected"
                    : ""
                }`}
                onClick={() => focusMockup(i)}
                key={i.mockupsNr}
              >
                <div className="mm-pod-mockup-thumb-item-img">
                  <div>
                    <img
                      src={
                        base64img
                          ? base64img
                          : "https://static.mediamodifier.com/pod-editor/spinner.gif"
                      }
                      alt={""}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MockupListView;
