import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {setNav} from "../../store/actions/app"

// Navigation button for layout, use redux to change state
const NavButton = ({ index, title }) => {
  const indexSelected = useSelector(({ app }) => app.indexSelected);
  const dispatch = useDispatch();
  const setSelected = () => {
    dispatch(setNav(index));
  };
  return (
    <button
      onClick={setSelected}
      type="button"
      className={`nav-link ${
        indexSelected === index ? "active" : ""
      }`}
    >
      {title}
    </button>
  );
};
export default NavButton;
