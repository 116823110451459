import * as actionTypes from "../actions/actionTypes";
import { fabric } from "fabric";
import axios from "axios";
import { getScaleRatio } from "../../helpers/helpers";

/**
 * adds rendered mockup thumbnail to memory
 * @param {string} nr mockup nr
 * @param {string} base64 thumbnail sized rendered base64 image
 * @returns undefined
 */
export const saveMockupThumbnail =
  (base64, mockupsNr) => (dispatch, getState) => {
    dispatch({
      type: actionTypes.ADD_UPDATE_MOCKUP_THUMBNAIL,
      payload: { base64, mockupsNr },
    });
  };

/**
 * adds rendered full mockup to memory
 * @param {string} nr mockup nr
 * @param {string} base64 full sized rendered base64 image
 * @returns undefined
 */
export const saveMockupFull = (base64, mockupsNr) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.ADD_UPDATE_MOCKUP_FULL,
    payload: { base64, mockupsNr },
  });
};

export const getFullMockup = (nr) => (dispatch, getState) => {
  try {
    const { mockups, pages, mockupSelected, mockupFullList, colors } =
      getState().image;
    if (mockupFullList.find((f) => f.mockupsNr === nr)) {
      return;
    }
    let promises = [];
    let c = new fabric.Canvas();
    mockups
      .filter((f) => f.mockupsNr === mockupSelected.mockupsNr)
      .forEach((mockup) => {
        const page = pages.find((f) => f.id === mockup.viewId);
        promises.push(
          preparePageForRenderingMockup(c, page, mockup, "jpeg", colors)
        );
      });
    Promise.all(promises).then(
      function () {
        dispatch(renderMockupFull(arguments[0]));
      },
      function (err) {
        console.error(err);
      }
    );
  } catch (e) {
    console.error(e);
  }
};

export const getMockupThumbs = (nr) => (dispatch, getState) => {
  try {
    const { mockups, pages, mockupThumbsList, colors } = getState().image;
    // avoid multiple requests for same mockup
    if (mockupThumbsList.find((f) => f.mockupsNr === nr)) {
      return;
    }
    let promises = [];
    let c = new fabric.Canvas();
    mockups
      .filter((f) => f.mockupsNr === nr)
      .forEach((mockup) => {
        const page = pages.find((f) => f.id === mockup.viewId);
        promises.push(
          preparePageForRenderingMockup(c, page, mockup, "jpeg", colors)
        );
      });
    Promise.all(promises).then(
      function () {
        // group data by mockup Nr
        const groupedData = groupByNr(arguments[0]);
        if (groupedData) {
          groupedData.forEach((f) => {
            dispatch(renderMockupThumb(f));
          });
        }
      },
      function (err) {
        console.error(err);
      }
    );
  } catch (e) {
    console.error(e);
  }
};

/**
 * groups data by mockup Nr
 * @param {Array} array array of rendered pages
 * @returns array of rendered pages grouped by mockup Nr
 */
const groupByNr = (array) => {
  try {
    let res = {};
    if (!array || !array.length) {
      return;
    }
    array.forEach((f) => {
      if (res[f.mockupsNr]) {
        res[f.mockupsNr] = [...res[f.mockupsNr], f];
      } else {
        res[f.mockupsNr] = [f];
      }
    });
    return Object.values(res);
  } catch (e) {
    console.error(e);
  }
};

/**
 * forwards rendered pages to mockupAPI, persists response in store
 * @param {Array} group array of data, {data: base64, mockupsNr: String}
 * @returns undefined
 */
const renderMockupThumb = (group) => (dispatch, getState) => {
  try {
    if (!group || !group.length || !group[0].mockupsNr) {
      return;
    }
    const { domain, apiKey } = getState().app;
    const { mockupsNr } = group[0];
    axios
      .post(
        `${domain}/api/render`,
        {
          nr: mockupsNr,
          render_type: "thumb",
          layer_inputs: group.map((m) => {
            return {
              id: m.mockupsLayerUuId,
              data: m.data,
              checked: m.checked,
            };
          }),
          format: "jpeg",
        },
        {
          headers: {
            Authorization: `${apiKey}`,
          },
        }
      )
      .then((response) => {
        dispatch(saveMockupThumbnail(response.data.base64, mockupsNr));
      })
      .catch((error) => {
        if (error) {
          console.error(error);
        }
      });
  } catch (e) {
    console.error(e);
  }
};
/**
 * forwards rendered pages to renderer API, persists response in store
 * @param {Array} group array of data, {data: base64, mockupsNr: String}
 * @returns undefined
 */
const renderMockupFull = (group) => (dispatch, getState) => {
  try {
    const { mockupSelected } = getState().image;
    if (!group || !group.length || !group[0].mockupsNr || !mockupSelected) {
      return;
    }
    const { domain, apiKey } = getState().app;
    const { mockupsNr } = group[0];
    axios
      .post(
        `${domain}/api/render`,
        {
          nr: mockupsNr,
          render_type: "full",
          layer_inputs: group.map((m) => {
            return {
              id: m.mockupsLayerUuId,
              data: m.data,
              checked: m.checked,
            };
          }),
          format: "jpeg",
        },
        {
          headers: {
            Authorization: `${apiKey}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          saveMockupFull(response.data.base64, mockupSelected.mockupsNr)
        );
      })
      .catch((error) => {
        if (error) {
          console.error(error);
        }
      });
  } catch (e) {
    console.error(e);
  }
};
/**
 *
 * @param {fabric} canvas temporary canvas to render each page svg and png
 * @param {object} page page object {json, clip, id, name}
 */
export const preparePageForRenderingMockup = (
  canvas,
  page,
  mockup,
  type,
  colors
) => {
  return new Promise(function (resolve, reject) {
    try {
      let data = null;
      // ignore empty layers! checked: false
      canvas.clear();
      canvas.renderAll();
      canvas.loadFromJSON(
        page && page.json && page.clip ? page.json : {},
        function () {
          const pageW =
            page && page.clip && page.clip.width ? page.clip.width : null;
          const pageH =
            page && page.clip && page.clip.height ? page.clip.height : null;
          const mockupPlaceholderHeight = mockup.height;
          const mockupPlaceholderWidth = mockup.width;
          let scaleRatio = getScaleRatio(
            pageW,
            pageH,
            mockupPlaceholderWidth,
            mockupPlaceholderHeight
          );
          canvas.backgroundColor = colors[0];
          canvas.overlayImage = null;
          canvas.backgroundImage = null;
          canvas.renderAll();
          data = canvas.toDataURL({
            format: type,
            multiplier: scaleRatio,
            top: page ? page.clip.y : undefined,
            left: page ? page.clip.x : undefined,
            width: page ? page.clip.width : undefined,
            height: page ? page.clip.height : undefined,
            quality: 0.8,
          });
          canvas.clear();
          canvas.renderAll();
          const checked = page && page.json ? true : false;
          resolve({
            data: data,//checked === true ? data : null,
            checked: true,//checked,
            ...mockup,
          });
        }
      );
    } catch (error) {
      reject(error);
    }
  });
};
/**
 * action to select mockup from mockup list
 * @param {string} nr
 * @param {string} mockupId
 * @param {string} id
 * @returns select mockup function
 */
export const selectMockup = (mockup) => (dispatch, getState) => {
  const { mockupsNr, mockupsLayerUuId, viewId, id } = mockup;
  dispatch({
    type: actionTypes.MOCKUP_SELECTED,
    payload: {
      mockupsNr: mockupsNr,
      mockupsLayerUuId: mockupsLayerUuId,
      viewId: viewId,
      id: id,
    },
  });
};
/**
 * removes rendered mockups associated with template id from memory
 * @param {string} id template id
 * @returns undefined
 */
export const clearFullMockup = (id) => (dispatch, getState) => {
  const { mockups } = getState().image;
  if (id && mockups && mockups.length) {
    const mockupsNrList = mockups
      .filter((f) => f.viewId === id)
      .map((m) => m.mockupsNr);
    dispatch({
      type: actionTypes.CLEAR_FULL_MOCKUP,
      payload: { idList: mockupsNrList },
    });
  }
};

export const clearMockupPreviews = (id) => (dispatch, getState) => {
  const { mockups } = getState().image;
  if (id && mockups && mockups.length) {
    const mockupsNrList = mockups
      .filter((f) => f.viewId === id)
      .map((m) => m.mockupsNr);
    dispatch({
      type: actionTypes.CLEAR_FULL_MOCKUP,
      payload: { idList: mockupsNrList },
    });
    dispatch({
      type: actionTypes.CLEAR_THUMB_MOCKUP,
      payload: { idList: mockupsNrList },
    });
  }
};
