import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setProperty } from "../../../../store/actions/image";

// font size hook
const FontSizeHook = ({element}) => {
  const dispatch = useDispatch();
  const options = useRef([...Array(128)].map((m, ind) => {
    return {
      label: ind + 1,
      value: ind + 1,
    };
  }))
  const template = useSelector(({ image }) => image.template);
  const pageObjects = useSelector(({ image }) => {
    if (template && template.id) {
      const page = image.pages.find((p) => p.id === template.id);
      if (page && page.json && page.json.objects && page.json.objects.length) {
        return page.json.objects;
      }
      return [];
    } else {
      return [];
    }
  });
  const changeFontSize = (val) => {
    dispatch(setProperty(element.id, val.value, "fontSize"));
  };
  const item = pageObjects.find((f) => f.id === element.id);

  if (!item) {
    return { onChange: changeFontSize, value: null, options: options.current };
  }
  const value = options.current.find(f=>f.value===item.fontSize);
  return { onChange: changeFontSize, value: value, options: options.current };
};

export default FontSizeHook;
