import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fonts } from "../../../../constants/constants";
import { setProperty } from "../../../../store/actions/image";

// font family hook
const FontFamilyHook = (props) => {
  const { element } = props;
  const dispatch = useDispatch();
  const template = useSelector(({ image }) => image.template);
  const pageObjects = useSelector(({ image }) => {
    if (template && template.id) {
      const page = image.pages.find((p) => p.id === template.id);
      if (page && page.json && page.json.objects && page.json.objects.length) {
        return page.json.objects;
      }
      return [];
    } else {
      return [];
    }
  });
  const fontOptions = Object.keys(fonts).map((key, idx) => {
    return {
      label: <span style={{ fontFamily: key }}>{fonts[key].name}</span>,
      value: idx,
      weight: fonts[key].weights[0],
      name: fonts[key].name,
    };
  });
  const changeFont = (val) => {
    dispatch(setProperty(element.id, val.name, "fontFamily"));
    dispatch(setProperty(element.id, val.weight, "fontWeight"));
  };
  const item = pageObjects.find((f) => f.id === element.id);

  if (!item) {
    return { onChange: changeFont, value: null, options: fontOptions };
  }
  const value = fontOptions
    ? fontOptions.find((f) => f.name === item.fontFamily)
    : null;
  return { onChange: changeFont, value: value, options: fontOptions };
};

export default FontFamilyHook;
