export const deleteIcon =
  "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
export const rotateIcon =
  "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='512pt' viewBox='0 0 512 512' width='512pt'%3E%3Cpath d='m61.496094 279.609375c-.988282-8.234375-1.496094-16.414063-1.496094-23.609375 0-107.402344 88.597656-196 196-196 50.097656 0 97 20.199219 131.5 51.699219l-17.300781 17.601562c-3.898438 3.898438-5.398438 9.597657-3.898438 15 1.800781 5.097657 6 9 11.398438 10.199219 3.019531.605469 102.214843 32.570312 95.898437 31.300781 8.035156 2.675781 19.917969-5.894531 17.703125-17.699219-.609375-3.023437-22.570312-113.214843-21.300781-106.902343-1.199219-5.398438-5.101562-9.898438-10.5-11.398438-5.097656-1.5-10.800781 0-14.699219 3.898438l-14.699219 14.398437c-45.300781-42.296875-107.503906-68.097656-174.101562-68.097656-140.699219 0-256 115.300781-256 256v.597656c0 8.457032.386719 14.992188.835938 19.992188.597656 6.625 5.480468 12.050781 12.003906 13.359375l30.816406 6.160156c10.03125 2.007813 19.050781-6.402344 17.839844-16.5zm0 0'/%3E%3Cpath d='m499.25 222.027344-30.90625-6.296875c-10.042969-2.046875-19.125 6.371093-17.890625 16.515625 1.070313 8.753906 1.546875 17.265625 1.546875 23.753906 0 107.398438-88.597656 196-196 196-50.097656 0-97-20.199219-131.5-52l17.300781-17.300781c3.898438-3.898438 5.398438-9.597657 3.898438-15-1.800781-5.101563-6-9-11.398438-10.199219-3.019531-.609375-102.214843-32.570312-95.898437-31.300781-5.101563-.898438-10.203125.601562-13.5 4.199219-3.601563 3.300781-5.101563 8.699218-4.203125 13.5.609375 3.019531 22.574219 112.210937 21.304687 105.898437 1.195313 5.402344 5.097656 9.902344 10.496094 11.398437 6.261719 1.570313 11.488281-.328124 14.699219-3.898437l14.402343-14.398437c45.296876 42.300781 107.5 69.101562 174.398438 69.101562 140.699219 0 256-115.300781 256-256v-.902344c0-6.648437-.242188-13.175781-.796875-19.664062-.570313-6.628906-5.433594-12.074219-11.953125-13.40625zm0 0'/%3E%3C/svg%3E";
export const PlusIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="4" fill="none" />
    <g clipPath="url(#clip0)">
      <path
        d="M31 23H25V17C25 16.7348 24.8946 16.4804 24.7071 16.2929C24.5196 16.1054 24.2652 16 24 16C23.7348 16 23.4804 16.1054 23.2929 16.2929C23.1054 16.4804 23 16.7348 23 17V23H17C16.7348 23 16.4804 23.1054 16.2929 23.2929C16.1054 23.4804 16 23.7348 16 24C16 24.2652 16.1054 24.5196 16.2929 24.7071C16.4804 24.8946 16.7348 25 17 25H23V31C23 31.2652 23.1054 31.5196 23.2929 31.7071C23.4804 31.8946 23.7348 32 24 32C24.2652 32 24.5196 31.8946 24.7071 31.7071C24.8946 31.5196 25 31.2652 25 31V25H31C31.2652 25 31.5196 24.8946 31.7071 24.7071C31.8946 24.5196 32 24.2652 32 24C32 23.7348 31.8946 23.4804 31.7071 23.2929C31.5196 23.1054 31.2652 23 31 23Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const PlusRoundIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="none" />
    <path
      d="M27 19H21V13C21 12.7348 20.8946 12.4804 20.7071 12.2929C20.5196 12.1054 20.2652 12 20 12C19.7348 12 19.4804 12.1054 19.2929 12.2929C19.1054 12.4804 19 12.7348 19 13V19H13C12.7348 19 12.4804 19.1054 12.2929 19.2929C12.1054 19.4804 12 19.7348 12 20C12 20.2652 12.1054 20.5196 12.2929 20.7071C12.4804 20.8946 12.7348 21 13 21H19V27C19 27.2652 19.1054 27.5196 19.2929 27.7071C19.4804 27.8946 19.7348 28 20 28C20.2652 28 20.5196 27.8946 20.7071 27.7071C20.8946 27.5196 21 27.2652 21 27V21H27C27.2652 21 27.5196 20.8946 27.7071 20.7071C27.8946 20.5196 28 20.2652 28 20C28 19.7348 27.8946 19.4804 27.7071 19.2929C27.5196 19.1054 27.2652 19 27 19Z"
      fill="white"
    />
  </svg>
);
export const PageIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#EEEEEE" />
  </svg>
);
export const ImageIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 15C12.75 13.7574 13.7574 12.75 15 12.75H25C26.2426 12.75 27.25 13.7574 27.25 15V21.1893L26.5303 20.4697L26.52 20.4596C25.9542 19.9151 25.2585 19.5801 24.5 19.5801C23.7415 19.5801 23.0458 19.9151 22.48 20.4596L22.4697 20.4697L22 20.9393L19.5303 18.4697L19.52 18.4596C18.9542 17.9151 18.2585 17.5801 17.5 17.5801C16.7415 17.5801 16.0458 17.9151 15.48 18.4596L15.4697 18.4697L12.75 21.1893V15ZM23.0607 22L23.5249 21.5358C23.8695 21.206 24.2067 21.0801 24.5 21.0801C24.7933 21.0801 25.1305 21.206 25.4751 21.5358L27.25 23.3107V25C27.25 26.2426 26.2426 27.25 25 27.25H15C13.7574 27.25 12.75 26.2426 12.75 25V23.3107L16.5249 19.5358C16.8695 19.206 17.2067 19.0801 17.5 19.0801C17.7933 19.0801 18.1305 19.206 18.4751 19.5358L21.4697 22.5303L23.4697 24.5303C23.7626 24.8232 24.2374 24.8232 24.5303 24.5303C24.8232 24.2374 24.8232 23.7626 24.5303 23.4697L23.0607 22ZM28.75 23V15C28.75 12.9289 27.0711 11.25 25 11.25H15C12.9289 11.25 11.25 12.9289 11.25 15V23V25C11.25 27.0711 12.9289 28.75 15 28.75H25C27.0711 28.75 28.75 27.0711 28.75 25V23ZM23 15.25C22.5858 15.25 22.25 15.5858 22.25 16C22.25 16.4142 22.5858 16.75 23 16.75H23.01C23.4242 16.75 23.76 16.4142 23.76 16C23.76 15.5858 23.4242 15.25 23.01 15.25H23Z"
      fill="white"
    />
  </svg>
);
export const ThreeDotsVertical = ({ id }) => (
  <svg
    id={`svg-${id}`}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
  <path
    d="M16,11.82a1.94,1.94,0,0,0,1.36-.56,1.92,1.92,0,0,0,0-2.71,2,2,0,0,0-2.71,0,1.9,1.9,0,0,0,0,2.71A1.9,1.9,0,0,0,16,11.82Zm-1.35,2.82A1.91,1.91,0,0,0,14.08,16a1.87,1.87,0,0,0,.57,1.35A1.92,1.92,0,0,0,17.92,16a1.94,1.94,0,0,0-.56-1.36A2,2,0,0,0,14.65,14.64Zm0,6.1a1.9,1.9,0,0,0,0,2.71A1.9,1.9,0,0,0,16,24a1.94,1.94,0,0,0,1.36-.56,1.92,1.92,0,0,0,0-2.71A2,2,0,0,0,14.65,20.74Z"
    fill="#222"
  />
</svg>
);
export const FileDeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.82323 3.82322C9.87011 3.77634 9.9337 3.75 10 3.75H14C14.0663 3.75 14.1299 3.77634 14.1768 3.82322C14.2237 3.87011 14.25 3.9337 14.25 4V6.25H9.75001V4C9.75001 3.9337 9.77635 3.87011 9.82323 3.82322ZM8.9983 7.75C8.99887 7.75 8.99944 7.75 9.00001 7.75C9.00058 7.75 9.00115 7.75 9.00172 7.75H14.9983C14.9989 7.75 14.9994 7.75 15 7.75C15.0006 7.75 15.0011 7.75 15.0017 7.75H18.1849L17.2526 18.9377C17.2509 18.9584 17.25 18.9792 17.25 19C17.25 19.3315 17.1183 19.6495 16.8839 19.8839C16.6495 20.1183 16.3315 20.25 16 20.25H8.00001C7.66849 20.25 7.35055 20.1183 7.11613 19.8839C6.88171 19.6495 6.75001 19.3315 6.75001 19C6.75001 18.9792 6.74915 18.9584 6.74742 18.9377L5.81511 7.75H8.9983ZM8.25001 6.25V4C8.25001 3.53587 8.43438 3.09075 8.76257 2.76256C9.09076 2.43437 9.53588 2.25 10 2.25H14C14.4641 2.25 14.9093 2.43437 15.2374 2.76256C15.5656 3.09075 15.75 3.53587 15.75 4V6.25H18.9912H19.0071H20C20.4142 6.25 20.75 6.58578 20.75 7C20.75 7.41421 20.4142 7.75 20 7.75H19.6901L18.7498 19.0337C18.741 19.7508 18.4523 20.4368 17.9446 20.9445C17.4288 21.4603 16.7294 21.75 16 21.75H8.00001C7.27067 21.75 6.57119 21.4603 6.05547 20.9445C5.54768 20.4368 5.25899 19.7508 5.25022 19.0337L4.30991 7.75H4C3.58579 7.75 3.25 7.41421 3.25 7C3.25 6.58578 3.58579 6.25 4 6.25H4.99292H5.00878H8.25001ZM10 10.25C10.4142 10.25 10.75 10.5858 10.75 11V17C10.75 17.4142 10.4142 17.75 10 17.75C9.58579 17.75 9.25 17.4142 9.25 17V11C9.25 10.5858 9.58579 10.25 10 10.25ZM14.75 11C14.75 10.5858 14.4142 10.25 14 10.25C13.5858 10.25 13.25 10.5858 13.25 11V17C13.25 17.4142 13.5858 17.75 14 17.75C14.4142 17.75 14.75 17.4142 14.75 17V11Z"
      fill="#222222"
    />
  </svg>
);
export const TrashIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2746 9.27441C14.2902 9.25878 14.3114 9.25 14.3335 9.25H17.6668C17.6889 9.25 17.7101 9.25878 17.7258 9.27441C17.7414 9.29004 17.7502 9.31123 17.7502 9.33333V11.0833H14.2502V9.33333C14.2502 9.31123 14.2589 9.29004 14.2746 9.27441ZM13.499 12.5833C13.4994 12.5833 13.4998 12.5833 13.5002 12.5833C13.5006 12.5833 13.501 12.5833 13.5014 12.5833H18.499C18.4994 12.5833 18.4998 12.5833 18.5002 12.5833C18.5006 12.5833 18.501 12.5833 18.5014 12.5833H21.0184L20.2528 21.771C20.251 21.7918 20.2502 21.8125 20.2502 21.8333C20.2502 22.0764 20.1536 22.3096 19.9817 22.4815C19.8098 22.6534 19.5766 22.75 19.3335 22.75H12.6668C12.4237 22.75 12.1906 22.6534 12.0187 22.4815C11.8468 22.3096 11.7502 22.0764 11.7502 21.8333C11.7502 21.8125 11.7493 21.7918 11.7476 21.771L10.9819 12.5833H13.499ZM12.7502 11.0833V9.33333C12.7502 8.91341 12.917 8.51068 13.2139 8.21375C13.5108 7.91681 13.9136 7.75 14.3335 7.75H17.6668C18.0868 7.75 18.4895 7.91681 18.7864 8.21375C19.0834 8.51068 19.2502 8.91341 19.2502 9.33333V11.0833H21.8247H21.8406H22.6668C23.081 11.0833 23.4168 11.4191 23.4168 11.8333C23.4168 12.2475 23.081 12.5833 22.6668 12.5833H22.5236L21.7499 21.8674C21.7411 22.496 21.4875 23.097 21.0423 23.5422C20.5891 23.9954 19.9744 24.25 19.3335 24.25H12.6668C12.0259 24.25 11.4112 23.9954 10.958 23.5422C10.5128 23.097 10.2593 22.496 10.2504 21.8674L9.47674 12.5833H9.3335C8.91928 12.5833 8.5835 12.2475 8.5835 11.8333C8.5835 11.4191 8.91928 11.0833 9.3335 11.0833H10.1597H10.1756H12.7502ZM14.3335 14.4167C14.7477 14.4167 15.0835 14.7525 15.0835 15.1667V20.1667C15.0835 20.5809 14.7477 20.9167 14.3335 20.9167C13.9193 20.9167 13.5835 20.5809 13.5835 20.1667V15.1667C13.5835 14.7525 13.9193 14.4167 14.3335 14.4167ZM18.4168 15.1667C18.4168 14.7525 18.0811 14.4167 17.6668 14.4167C17.2526 14.4167 16.9168 14.7525 16.9168 15.1667V20.1667C16.9168 20.5809 17.2526 20.9167 17.6668 20.9167C18.0811 20.9167 18.4168 20.5809 18.4168 20.1667V15.1667Z"
      fill="#222222"
    />
  </svg>
);

export const DuplicateIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.58337C10.359 8.58337 9.74435 8.83799 9.29114 9.2912C8.83793 9.74441 8.58331 10.3591 8.58331 11V17.6667C8.58331 18.3076 8.83793 18.9223 9.29114 19.3755C9.74435 19.8288 10.359 20.0834 11 20.0834H11.9167V21C11.9167 22.3347 12.9986 23.4167 14.3333 23.4167H21C22.3347 23.4167 23.4167 22.3347 23.4167 21V14.3334C23.4167 12.9987 22.3347 11.9167 21 11.9167H20.0833V11C20.0833 10.3591 19.8287 9.74441 19.3755 9.2912C18.9223 8.83799 18.3076 8.58337 17.6666 8.58337H11ZM18.5833 11.9167V11C18.5833 10.7569 18.4867 10.5238 18.3148 10.3519C18.1429 10.18 17.9098 10.0834 17.6666 10.0834H11C10.7569 10.0834 10.5237 10.18 10.3518 10.3519C10.1799 10.5238 10.0833 10.7569 10.0833 11V17.6667C10.0833 17.9098 10.1799 18.143 10.3518 18.3149C10.5237 18.4868 10.7569 18.5834 11 18.5834H11.9167V14.3334C11.9167 12.9987 12.9986 11.9167 14.3333 11.9167H18.5833ZM13.4167 14.3334C13.4167 13.8271 13.8271 13.4167 14.3333 13.4167H21C21.5062 13.4167 21.9167 13.8271 21.9167 14.3334V21C21.9167 21.5063 21.5062 21.9167 21 21.9167H14.3333C13.8271 21.9167 13.4167 21.5063 13.4167 21V14.3334Z"
      fill="#222222"
    />
  </svg>
);
export const TextThumbnail = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="none" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 11.25C18.6874 11.25 18.4075 11.4439 18.2978 11.7367L12.4802 27.25H12C11.5858 27.25 11.25 27.5858 11.25 28C11.25 28.4142 11.5858 28.75 12 28.75H12.9825C12.9941 28.7503 13.0057 28.7503 13.0173 28.75H15C15.4142 28.75 15.75 28.4142 15.75 28C15.75 27.5858 15.4142 27.25 15 27.25H14.0823L15.3948 23.75H21.3863L22.868 27.25H22C21.5858 27.25 21.25 27.5858 21.25 28C21.25 28.4142 21.5858 28.75 22 28.75H23.9834C23.9947 28.7503 24.006 28.7503 24.0173 28.75H27.9836C27.9948 28.7503 28.006 28.7503 28.0172 28.75H29C29.4142 28.75 29.75 28.4142 29.75 28C29.75 27.5858 29.4142 27.25 29 27.25H28.4905L21.6871 11.6994C21.5677 11.4264 21.298 11.25 21 11.25H19ZM26.8532 27.25L20.5095 12.75H19.5198L18.9742 14.2049L24.4969 27.25H26.8532ZM18.2091 16.2452L15.9573 22.25H20.7512L18.2091 16.2452Z"
      fill="white"
    />
  </svg>
);
export const GrayTextThumbnail = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#EEEEEE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 11.25C18.6874 11.25 18.4075 11.4439 18.2978 11.7367L12.4802 27.25H12C11.5858 27.25 11.25 27.5858 11.25 28C11.25 28.4142 11.5858 28.75 12 28.75H12.9825C12.9941 28.7503 13.0057 28.7503 13.0173 28.75H15C15.4142 28.75 15.75 28.4142 15.75 28C15.75 27.5858 15.4142 27.25 15 27.25H14.0823L15.3948 23.75H21.3863L22.868 27.25H22C21.5858 27.25 21.25 27.5858 21.25 28C21.25 28.4142 21.5858 28.75 22 28.75H23.9834C23.9947 28.7503 24.006 28.7503 24.0173 28.75H27.9836C27.9948 28.7503 28.006 28.7503 28.0172 28.75H29C29.4142 28.75 29.75 28.4142 29.75 28C29.75 27.5858 29.4142 27.25 29 27.25H28.4905L21.6871 11.6994C21.5677 11.4264 21.298 11.25 21 11.25H19ZM26.8532 27.25L20.5095 12.75H19.5198L18.9742 14.2049L24.4969 27.25H26.8532ZM18.2091 16.2452L15.9573 22.25H20.7512L18.2091 16.2452Z"
      fill="#222222"
    />
  </svg>
);

// TEXT ATTRIBUTE BUTTON SVGs
export const JustifyLeft = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33334 5H16.6667"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33334 10H11.6667"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33334 15H15"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const JustifyRight = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33334 5H16.6667"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33334 10H16.6667"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15H16.6667"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const JustifyCenter = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33331 5H16.6666"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33331 10H16.6666"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33331 15H16.6666"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Justify = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33331 5H16.6666"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33331 10H16.6666"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.33331 15H16.6666"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const StrikeThrough = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16669 10H15.8334"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 5.41667C13.1444 5.04997 12.7113 4.72637 12.1054 4.49917C11.4995 4.27196 10.757 4.15469 10 4.16667H9.16667C8.39312 4.16667 7.65125 4.47396 7.10427 5.02094C6.55729 5.56792 6.25 6.30978 6.25 7.08333C6.25 7.85688 6.55729 8.59875 7.10427 9.14573C7.65125 9.69271 8.39312 10 9.16667 10"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.75 13.3333C13.6491 14.0326 13.2978 14.6715 12.7614 15.1313C12.2249 15.5911 11.5398 15.8406 10.8333 15.8333H9.58333C8.82636 15.8453 8.0838 15.728 7.47792 15.5008C6.87203 15.2736 6.43896 14.95 6.25 14.5833"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Italic = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16669 4.16669H14.1667"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83331 15.8333H10.8333"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6666 4.16669L8.33331 15.8334"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Underline = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 16.6667H15"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66669 4.16669V9.16669C6.66669 10.0507 7.01788 10.8986 7.643 11.5237C8.26812 12.1488 9.11596 12.5 10 12.5C10.8841 12.5 11.7319 12.1488 12.357 11.5237C12.9822 10.8986 13.3334 10.0507 13.3334 9.16669V4.16669"
      stroke="#222222"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const Overline = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5,2.8v1h10v-1H5z M12.8,12.5c0,0.8-0.3,1.5-0.8,2c-1.1,1.1-2.9,1.1-4,0c-0.5-0.5-0.8-1.2-0.8-2v-5h-1v5
      c0,1,0.4,2,1.1,2.7C8,15.9,9,16.3,10,16.3c1,0,2-0.4,2.7-1.1c0.7-0.7,1.1-1.7,1.1-2.7v-5h-1V12.5z"
    />
  </svg>
);
export const CenterText = (width = "20", height = "20") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33334 5H16.6667"
      stroke="#222222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66666 10H13.3333"
      stroke="#222222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 15H15"
      stroke="#222222"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const MajorTick = () => (
  <svg
    width="2"
    height="24"
    viewBox="0 0 2 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2" height="24" rx="1" fill="none" />
  </svg>
);
export const MinorTick = () => (
  <svg
    width="2"
    height="16"
    viewBox="0 0 2 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="2" height="16" rx="1" fill="#AFC5FF" />
  </svg>
);
// ZOOM SVGs
export const ZoomHandle = () => (
  <svg
    style={{
      width: "100%",
      height: "100%",
      padding: "7px",
    }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.71 19.29L17.31 15.9C18.407 14.5025 19.0022 12.7767 19 11C19 9.41775 18.5308 7.87103 17.6518 6.55544C16.7727 5.23985 15.5233 4.21447 14.0615 3.60897C12.5997 3.00347 10.9911 2.84504 9.43928 3.15372C7.88743 3.4624 6.46197 4.22433 5.34315 5.34315C4.22433 6.46197 3.4624 7.88743 3.15372 9.43928C2.84504 10.9911 3.00347 12.5997 3.60897 14.0615C4.21447 15.5233 5.23985 16.7727 6.55544 17.6518C7.87103 18.5308 9.41775 19 11 19C12.7767 19.0022 14.5025 18.407 15.9 17.31L19.29 20.71C19.383 20.8037 19.4936 20.8781 19.6154 20.9289C19.7373 20.9797 19.868 21.0058 20 21.0058C20.132 21.0058 20.2627 20.9797 20.3846 20.9289C20.5064 20.8781 20.617 20.8037 20.71 20.71C20.8037 20.617 20.8781 20.5064 20.9289 20.3846C20.9797 20.2627 21.0058 20.132 21.0058 20C21.0058 19.868 20.9797 19.7373 20.9289 19.6154C20.8781 19.4936 20.8037 19.383 20.71 19.29ZM5 11C5 9.81332 5.3519 8.65328 6.01119 7.66658C6.67047 6.67989 7.60755 5.91085 8.7039 5.45673C9.80026 5.0026 11.0067 4.88378 12.1705 5.11529C13.3344 5.3468 14.4035 5.91825 15.2426 6.75736C16.0818 7.59648 16.6532 8.66558 16.8847 9.82946C17.1162 10.9933 16.9974 12.1997 16.5433 13.2961C16.0892 14.3925 15.3201 15.3295 14.3334 15.9888C13.3467 16.6481 12.1867 17 11 17C9.4087 17 7.88258 16.3679 6.75736 15.2426C5.63214 14.1174 5 12.5913 5 11Z"
      fill="white"
    />
  </svg>
);
export const ZoomSlider = () => (
  <svg
    width="864"
    height="40"
    viewBox="0 0 864 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="864" height="40" rx="20" fill="none" />
  </svg>
);
