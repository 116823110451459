import WebFont from "webfontloader";
import { fonts } from "../constants/constants";

export const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const download = (filename, text) => {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const loadFonts = () => {
  const fontsToLoad = Object.keys(fonts).map((key, idx) => {
    return `${fonts[key].name}${
      fonts[key].weights ? `:${fonts[key].weights.join(",")}` : ""
    }`;
  });
  WebFont.load({
    google: {
      families: fontsToLoad,
    },
  });
};

/**
 * get amount to scale image to max 1000px
 * @param {Number} width Image width
 * @param {Number} height Image height
 */
export const getScaleRatio = (
  width,
  height,
  preDefinedWidth,
  preDefinedHeight
) => {
  try {
    const w = parseInt(width);
    const h = parseInt(height);
    const maxRes = 1000;
    if (!w || !h) {
      return 1;
    }
    if (preDefinedWidth && preDefinedWidth < w) {
      return preDefinedWidth / w;
    }
    // image already smaller than 1000, ignore
    if (w < maxRes && h < maxRes) {
      return 1;
    }
    if (w > h) {
      return maxRes / w;
    } else {
      return maxRes / h;
    }
  } catch (e) {
    console.error(e);
    return 1;
  }
};
