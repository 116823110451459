import React from "react";
import { useDispatch } from "react-redux";
import { setFontStyle } from "../../../store/actions/image";
import { useSelector } from "react-redux";
import {
  TypeItalic,
} from 'react-bootstrap-icons';

// Alignment option buttons
const ItalicButton = ({ element, Icon }) => {
  const dispatch = useDispatch();
  const canvas = useSelector(({ image }) => image.canvas);
  const changeFontStyle = (val) => {
    let item = getElement(element.id);
    if (!item) {
      return;
    }
    val = item === "italic" ? "normal" : "italic";
    dispatch(setFontStyle(element.id, val));
  };
  const getElement = (id) => {
    const item = canvas.getObjects().find((f) => f.id === id);
    if (item) {
      return item.fontStyle;
    } else {
      return null;
    }
  };
  return (
    <button
      className={`btn btn-icon btn-icon-sm btn-icon-${
        getElement(element.id) === "italic" ? "-active" : ""
      }`}
      onClick={() => changeFontStyle()}
    >
      <TypeItalic />
    </button>
  );
};

export default ItalicButton;
