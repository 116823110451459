import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { fabric } from "fabric";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { addViewArea, loadTemplateImage } from "../../../store/actions/admin";
import { ZOOM_FACTOR } from "../../../constants/constants";
import spinner from "../../../images/spinner.gif";

// Canvas function
const Canvas = () => {
  const canvasRef = useRef();
  const dispatch = useDispatch();
  const [canvas, setCanvas] = useState();
  const pageSelected = useSelector(({ admin }) => admin.pageSelected);
  const canvasGlobal = useSelector(({ image }) => image.canvas);
  const initDone = useSelector(({ app }) => app.initDone);

  const getFabric = () => {
    let c = new fabric.Canvas(canvasRef.current);
    // dev
    window.canvas = c;
    // Zoom to canvas on mouse scroll event listener
    c.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = c.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      c.zoomToPoint({ x: opt.pointer.x, y: opt.pointer.y }, zoom);
      dispatch({
        type: actionTypes.SET_ZOOM,
        payload: c.getZoom() / ZOOM_FACTOR,
      });
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    c.on("selection:cleared", function (e) {
      dispatch({
        type: actionTypes.END_EDIT,
      });
    });
    dispatch({
      type: actionTypes.SET_CANVAS,
      payload: c,
    });
    return c;
  };
  useEffect(() => {
    if (!canvas) {
      const cnv = getFabric();
      setCanvas(cnv);
      dispatch({ type: actionTypes.SET_CANVAS, payload: cnv });
    }
  }, []);
  useEffect(() => {
    if (!canvasGlobal) {
      return;
    }
    canvasGlobal.backgroundImage = null;
    canvasGlobal.clear();
    if (!pageSelected || !pageSelected.ref) {
      return;
    }
    dispatch(loadTemplateImage());
  }, [pageSelected]);
  const addView = () => {
    dispatch(addViewArea());
  };
  const showAddClippingAreaOverlay =
    pageSelected.clip &&
    pageSelected.ref &&
    pageSelected.clip.type === undefined
      ? true
      : false;
  return (
    <figure id="mm-pod-canvas-container" className={`mm-pod-main-canvas`}>
      {!initDone && <div className="mm-pod-loading"><img src={spinner} /></div>}
      <canvas
        ref={canvasRef}
        className={`mm-pod-canvas ${
          showAddClippingAreaOverlay ? "mm-pod-canvas-blur" : ""
        }`}
      ></canvas>
      <button
        style={{
          position: "absolute",
          top: "40%",
          left: "40%",
        }}
        onClick={addView}
        className={`mm-pod-btn-admin-add-view ${
          showAddClippingAreaOverlay ? "" : "d-none"
        }`}
      >
        Add view area
      </button>
    </figure>
  );
};

export default Canvas;
