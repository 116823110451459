import ReactDOM from "react-dom";
import * as actionTypes from "../../store/actions/actionTypes";

/**
 * Close app, remove from DOM
 */
export const closeModal = () => (dispatch, getState) => {
  const { defaultContainer } = getState().app;
  //   dispatch({ type: actionTypes.CLOSE_MODAL });
  // Close app, remove from DOM
  ReactDOM.unmountComponentAtNode(document.getElementById(defaultContainer));
};

/**
 *
 * @param {int} index navigation index
 * @returns undefined
 */
export const setNav = (index) => (dispatch, getState) => {
  dispatch({
    type: actionTypes.NAV_CLICKED,
    payload: index,
  });
};
