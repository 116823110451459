import React from "react";
import { useDispatch } from "react-redux";
import { toggleProperty } from "../../../store/actions/image";
import { useSelector } from "react-redux";
import {
  TypeStrikethrough,
} from 'react-bootstrap-icons';
import {
  Overline,
} from "../../icons/Icons";

// Alignment option buttons
const BoolAttributeButton = ({ element, att }) => {
  const dispatch = useDispatch();
  const canvas = useSelector(({ image }) => image.canvas);
  const changeProperty = (val) => {
    dispatch(toggleProperty(element.id, att));
  };
  const getValue = (id) => {
    const item = canvas.getObjects().find((f) => f.id === id);
    if (item) {
      return item[att];
    } else {
      return null;
    }
  };
  let icon;

  switch (att)
  {
    case 'linethrough':
      icon = <TypeStrikethrough size={16} />;
      break;
    case 'overline':
      icon = Overline("15", "15");
      break;
    default:
      icon = '';
  }

  return (
    <button
      className={`btn btn-icon btn-icon-sm btn-icon-${
        getValue(element.id) ? "-active" : ""
      }`}
      onClick={() => changeProperty()}
    >
      {icon}
    </button>
  );
};

export default BoolAttributeButton;
