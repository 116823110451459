import React from "react";
import { style } from "../../../../constants/constants";
import Select from "react-select";

// Text attribute options
const SelectOption = ({  
  placeholder,
  options,
  value,
  onChange
}) => {
 
  return (
    <Select
      styles={style}
      menuPortalTarget={document.body}
      options={options || null}
      value={value || null}
      onChange={onChange || null}
      placeholder={placeholder || null}
    />
  );
};

export default SelectOption;
