import React from "react";
import { useDispatch } from "react-redux";
import { setAlignment } from "../../../store/actions/image";
import { useSelector } from "react-redux";
import {
  JustifyLeft,
  JustifyRight,
  Justify,
  TextCenter,
} from 'react-bootstrap-icons';

// Alignment option buttons
const AlignmentButton = ({ element, align }) => {
  const dispatch = useDispatch();
  const canvas = useSelector(({ image }) => image.canvas);
  const changeAlignment = (val) => {
    dispatch(setAlignment(element.id, val));
  };
  const getElement = (id) => {
    const item = canvas.getObjects().find((f) => f.id === id);
    if (item) {
      return item.textAlign;
    } else {
      return null;
    }
  };
  let icon;

  switch( align )
  {
    case 'left':
      icon = <JustifyLeft size="16" />;
      break;
    case 'center':
      icon = <TextCenter size="16" />;
      break;
    case 'right':
      icon = <JustifyRight size="16" />;
      break;
    case 'justify':
      icon = <Justify size="16" />;
      break;
    default:
      icon = '';
  }

  return (
    <button
      className={`btn btn-icon btn-icon-sm btn-icon-${
        getElement(element.id) === align ? "-active" : ""
      }`}
      onClick={() => changeAlignment(align)}
    >
      {icon}
    </button>
  );
};

export default AlignmentButton;
