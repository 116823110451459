import MainView from "./containers/main/MainView";
import Sidebar from "./containers/sidebar/Sidebar";
import "./css/styles-screen.scss";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeModal } from "./store/actions/app";

/**
 * Modal overlay style
 */
const customStyles = {
  overlay: {
    backgroundColor: "rgb(180, 180, 180, 0.7)",
  },
  content: {
    padding: 0,
    borderRadius: "10px",
  },
};

/**
 *
 * @param {object} param0 app configuration object
 */
function App({ config }) {
  Modal.setAppElement(`#${config.defaultContainer}`);
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(({ app }) => app.modalIsOpen);

  const close = () => {
    dispatch(closeModal());
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={close}
      style={customStyles}
      portalClassName="ReactModalPortal mm-app-modal"
      contentLabel="Editor"
    >
      <div id="mm-pod-app">
        <article className="mm-pod-wrapper">
          <MainView />
          <Sidebar />
        </article>
      </div>
    </Modal>
  );
}

export default App;
