// Page selection button
const PageButton = ({ page, selected, onClick }) => {
  return (
      <button
        type="button"
        className={`btn btn-link ${selected === true ? "btn-link--active" : ""}`}
        onClick={() => onClick(page.id)}
      >
        {page.name}
      </button>
  );
};

export default PageButton;
