import Layout from "./Layout";
import Canvas from "../../components/canvas/Canvas";
import AdminCanvas from "../../components/admin/canvas/Canvas";
import PageSelection from "./PageSelection";
import AdminPageSelection from "./AdminPageSelection";
import Footer from "../../components/footer/Footer";
import { useSelector } from "react-redux";
import { DESIGN_VIEW, MOCKUP_VIEW } from "../../constants/constants";
import MockupView from "../../components/mockup/MockupView";
import AdminMockupView from "../../components/admin/mockup/AdminMockupView";

// main view with layout, pageselection, canvas and footer
const MainView = () => {
  const adminMode = useSelector(({ app }) => app.adminMode);
  const indexSelected = useSelector(({ app }) => app.indexSelected);

  const mainView = () => {
    switch (indexSelected) {
      case MOCKUP_VIEW:
        return adminMode ? <AdminMockupView /> : <MockupView />;
      case DESIGN_VIEW:
        return adminMode ? (
          <>
            <AdminPageSelection />
            <AdminCanvas />
            <Footer />
          </>
        ) : (
          <>
            <PageSelection />
            <Canvas />
            <Footer />
          </>
        );
      default:
        break;
    }
  };

  return (
    <section className="mm-pod-main">
      <Layout />
      {mainView()}
    </section>
  );
};

export default MainView;
