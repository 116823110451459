import React from "react";
import { useSelector } from "react-redux";
import NavButton from "../../components/layout/NavButton";
import { DESIGN_VIEW, MOCKUP_VIEW } from "../../constants/constants";

// Main view upper layout, for buttons to design and mockup view
const Layout = () => {
  const adminMode = useSelector(({ app }) => app.adminMode);

  return (
    <header className="mm-pod-main-header">
      <ul className="nav nav-tabs mb-0 border-bottom-0">
        <li className="nav-item">
          <NavButton index={DESIGN_VIEW} title="Design" />
        </li>
        {(!adminMode) && <li className="nav-item">
          <NavButton index={MOCKUP_VIEW} title="Mockup" />
        </li>}
      </ul>
    </header>
  );
};
export default Layout;
