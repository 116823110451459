import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AddOrUpdatePage } from "../../store/actions/image";
import PageButton from "../../components/page/PageButton";

// Page selection items overlaying canvas
const PageSelection = () => {
  const dispatch = useDispatch();
  const templates = useSelector(({ image }) => image.templateList);
  const template = useSelector(({ image }) => image.template);

  const changePage = (id) => {
    dispatch(AddOrUpdatePage(id));
  };

  return (
    <div className="mm-pod-main-options">
      <div className="row mx-n1 mm-pod-main-option">
        {templates && templates.length > 0
          ? templates.map((p) => (
              <div className="col-auto px-1 mb-2" key={p.id}>
                <PageButton
                  page={p}
                  selected={template.id === p.id ? true : false}
                  onClick={changePage}
                />
              </div>
            ))
          : ""}
        </div>
    </div>
  );
};

export default PageSelection;
