import React from "react";
import Slider, { Handle } from "rc-slider";
import "rc-slider/assets/index.css";
import { useDispatch, useSelector } from "react-redux";
import { setZoom } from "../../store/actions/image";
import { ZoomHandle, MajorTick, MinorTick } from "../icons/Icons";
import {
  ZoomTrackStyle,
  ZoomHandlerStyle,
  ZoomRailStyle,
} from "../styles/styles";

// Footer zoom manager
const Footer = () => {
  const dispatch = useDispatch();
  const zoom = useSelector(({ image }) => {
    // zoom level calculation, zoom out and zoom in equalized
    // y = k * e ^ (s * x)
    // x -> zoom slider position
    // y -> zoom level
    // k = 0.2, s = 1.6094
    return (1.6094 - Math.log(1 / image.zoom)) / 0.6438;
  });
  const changeValue = (val) => {
    if (isNaN(val)) {
      return;
    }
    //
    val = 0.2 * Math.pow(Math.E, 0.6438 * val);
    dispatch(setZoom(val));
  };
  return (
    <footer className="mm-pod-main-footer">
        <div
          style={{
            position: "absolute",
            left: "32px",
            right: "32px",
            height: "40px",
          }}
        >
          <div
            style={{
              backgroundColor: "#F5F5F5",
              width: "100%",
              height: "40px",
              borderRadius: "20px",
              display: "table",
            }}
          >
            <div style={{ display: "table-row" }}>
              <span
                style={{
                  flex: "0 0 0px",
                  display: "table-cell",
                  verticalAlign: "middle",
                  width: "15px",
                }}
              ></span>
              {[...Array(51)].map((e, i) => {
                return i === 0 ? (
                  <span key={i} style={{ opacity: 0 }}>
                    <MajorTick />
                  </span>
                ) : (
                  <span
                    style={{
                      flex: "0 0 0px",
                      display: "table-cell",
                      verticalAlign: "middle",
                    }}
                    className="flex"
                    key={i}
                  >
                    {i % 5 === 0 ? <MajorTick /> : <MinorTick />}
                  </span>
                );
              })}
              <span
                style={{
                  flex: "0 0 0px",
                  display: "table-cell",
                  verticalAlign: "middle",
                  width: "15px",
                }}
              ></span>
            </div>
          </div>
        </div>
        <Slider
          trackStyle={ZoomTrackStyle}
          railStyle={ZoomRailStyle}
          style={{ marginTop: 13, left: "15px", right: "15px" }}
          max={5}
          min={0}
          step={0.05}
          value={zoom}
          handle={(props) => {
            const { dragging, ...restProps } = props;
            return (
              <Handle dragging={dragging.toString()} {...restProps}>
                <ZoomHandle />
              </Handle>
            );
          }}
          handleStyle={ZoomHandlerStyle}
          onChange={changeValue}
        />
    </footer>
  );
};

export default Footer;
