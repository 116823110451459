import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { attributes } from "../../../../constants/constants";
import { TrackStyle, RailStyle, AttributeHandle } from "../../../styles/styles";

// Generic text attribute slider options
const SliderOptions = ({
  attribute,
  className,
  changeValue,
  value,
  max,
  min,
  step,
  dots,
}) => {
  return (
    <div className={className}>
      <div className="col">
        <b>{attributes.find((f) => f.value === attribute).label}</b>
      </div>
      <div className="col">
        <Slider
          trackStyle={TrackStyle}
          railStyle={RailStyle}
          handleStyle={AttributeHandle}
          dots={dots === true ? true : false}
          max={max ? max : 100}
          min={min ? min : 0}
          step={step ? step : 1}
          value={value || min}
          onChange={(val) => changeValue(val)}
        />
      </div>
      <div className="col">
        <input
          type="number"
          value={value || ""}
          onChange={(e) => changeValue(e.target.value)}
          className="form-control"
          style={{ height: "36px" }}
        />
      </div>
    </div>
  );
};

export default SliderOptions;
