import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fabric } from "fabric";
import * as actionTypes from "../../store/actions/actionTypes";
import { deleteIcon, rotateIcon } from "../icons/Icons";
import {
  ppiScaleListener,
  deleteImage,
  SavePage,
  loadTemplateImage,
  saveCanvasState,
} from "../../store/actions/image";
import { clearFullMockup } from "../../store/actions/mockups";
import { ZOOM_FACTOR } from "../../constants/constants";
import spinner from "../../images/spinner.gif";

// Canvas function
const Canvas = () => {
  const canvasRef = useRef();
  const dispatch = useDispatch();
  const [canvas, setCanvas] = useState();
  const template = useSelector(({ image }) => image.template);
  const pages = useSelector(({ image }) => image.pages);
  const initDone = useSelector(({ app }) => app.initDone);
  const clipArea = useSelector(({ image }) => {
    let page = image.pages.find((f) => f.id === template.id);
    if (page) {
      return page.clip;
    } else {
      return null;
    }
  });

  const getFabric = () => {
    const delImg = document.createElement("img");
    delImg.src = deleteIcon;
    const rotImg = document.createElement("img");
    rotImg.src = rotateIcon;

    // Add delete control to fabric objects
    // Handle delete with redux
    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -24,
      offsetX: 24,
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: renderIcon,
      cornerSize: 24,
    });
    fabric.Object.prototype.set({
      transparentCorners: false,
      cornerStyle: "circle",
      cornerColor: "#ffffff",
      cornerStrokeColor: "#a0a0a0",
      padding: 10,
    });
    // Delete object redux handler
    function deleteObject(eventData, e) {
      const idToDelete = e.target.id;
      dispatch(deleteImage(idToDelete));
    }
    // Render the delete control near the top right corner
    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(delImg, -size / 2, -size / 2, size, size);
      ctx.restore();
    }
    let c = new fabric.Canvas(canvasRef.current);

    // panning handling
    function startPan(event) {
      if (event.button !== 2) {
        return;
      }
      var x0 = event.screenX,
        y0 = event.screenY;
      function continuePan(event) {
        var x = event.screenX,
          y = event.screenY;
        c.relativePan({ x: x - x0, y: y - y0 });
        x0 = x;
        y0 = y;
      }
      function stopPan(event) {
        window.removeEventListener("mousemove", continuePan);
        window.removeEventListener("mouseup", stopPan);
      }
      window.addEventListener("mousemove", continuePan);
      window.addEventListener("mouseup", stopPan);
      window.addEventListener("contextmenu", cancelMenu);
    }
    function cancelMenu(e) {
      window.removeEventListener("contextmenu", cancelMenu);
      e.preventDefault();
      return false;
    }
    document
      .getElementById("mm-pod-canvas-container")
      .addEventListener("mousedown", startPan);
    // dev
    // window.canvas = c;
    c.preserveObjectStacking = true;
    // Zoom to canvas on mouse scroll event listener
    c.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = c.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      c.zoomToPoint({ x: opt.pointer.x, y: opt.pointer.y }, zoom);
      dispatch({
        type: actionTypes.SET_ZOOM,
        payload: c.getZoom() / ZOOM_FACTOR,
      });
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });
    c.on("selection:cleared", function (e) {
      dispatch({
        type: actionTypes.END_EDIT,
      });
    });
    c.on("object:modified", (e) => {
      dispatch(saveCanvasState());
      dispatch(clearFullMockup(template.id));
    });
    dispatch({
      type: actionTypes.SET_CANVAS,
      payload: c,
    });
    return c;
  };
  useEffect(() => {
    if (!canvas && template) {
      const cnv = getFabric();
      setCanvas(cnv);
    }
    // Save current canvas when exiting design view
    return () => {
      dispatch(SavePage());
    };
  }, [template]);
  // load json if present and render background, clipping box
  useEffect(() => {
    if (initDone === true) {
      if (!canvas) {
        return;
      }
      canvas.remove(...canvas.getObjects());
      // check if page already has a state saved, load if yes
      const pageJson = pages.find((f) => f.id === template.id);
      if (pageJson) {
        canvas.loadFromJSON(pageJson.json, function () {
          canvas.getObjects().forEach((f) =>
            // revive on click listener
            {
              if (f.type === "i-text") {
                f.on("selected", function (e) {
                  const obj = canvas.getActiveObject();
                  if (obj)
                    dispatch({
                      type: actionTypes.SET_ACTIVE_IMAGE,
                      payload: { id: obj.id, type: obj.type },
                    });
                });
              }
              if (f.type === "image") {
                f.on("scaled", (e) => ppiScaleListener(e, template, clipArea));
              }
            }
          );
          dispatch(loadTemplateImage());
        });
      } else {
        dispatch(loadTemplateImage());
      }
    }
  }, [template, initDone, canvas]);
  return (
    <figure id="mm-pod-canvas-container" className="mm-pod-main-canvas">
      {!initDone && (
        <div className="mm-pod-loading">
          <img src={spinner} />
        </div>
      )}
      <canvas ref={canvasRef} className="mm-pod-canvas"></canvas>
    </figure>
  );
};

export default Canvas;
