import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  // persist selected page
  template: null,
  // persist all pages with their clipping area, name, id
  templateList: [],
  activeObj: null,
  canvas: null,
  colors: [],
  // persist page json
  pages: [],
  index: 0,
  textChanged: 0,
  scale: null,
  zoom: 1,
  ppiAlert: [],
  mockupThumbsList: [],
  mockupFullList: [],
  mockupSelected: null,
  mockups: [],
};
const addUpdateMockupThumbnail = (state, action) => {
  if (
    state.mockupThumbsList &&
    state.mockupThumbsList.find((f) => f.mockupsNr === action.payload.mockupsNr)
  ) {
    // mockup thumb exists, update element
    let index = state.mockupThumbsList.findIndex(
      (f) => f.mockupsNr === action.payload.mockupsNr
    );
    return updateObject(state, {
      mockupThumbsList: [
        ...state.mockupThumbsList.slice(0, index),
        {
          ...action.payload,
        },
        ...state.mockupThumbsList.slice(index + 1),
      ],
    });
  } else {
    // page doesn't exist, create it
    return updateObject(state, {
      mockupThumbsList: [...state.mockupThumbsList, action.payload],
    });
  }
};

const addUpdateMockupFull = (state, action) => {
  if (
    state.mockupFullList &&
    state.mockupFullList.find((f) => f.mockupsNr === action.payload.mockupsNr)
  ) {
    // mockup thumb exists, update element
    let index = state.mockupFullList.findIndex(
      (f) => f.mockupsNr === action.payload.mockupsNr
    );
    return updateObject(state, {
      mockupFullList: [
        ...state.mockupFullList.slice(0, index),
        {
          ...action.payload,
        },
        ...state.mockupFullList.slice(index + 1),
      ],
    });
  } else {
    // page doesn't exist, create it
    return updateObject(state, {
      mockupFullList: [...state.mockupFullList, action.payload],
    });
  }
};
const addOrUpdatePage = (state, action) => {
  if (state.pages && state.pages.find((f) => f.id === action.payload.id)) {
    // page exists, update page
    let index = state.pages.findIndex((f) => f.id === action.payload.id);
    const newPage = { ...state.pages[index], ...action.payload };
    return updateObject(state, {
      pages: [
        ...state.pages.slice(0, index),
        {
          ...newPage,
        },
        ...state.pages.slice(index + 1),
      ],
    });
  } else {
    // page doesn't exist, create it
    return updateObject(state, {
      pages: [...state.pages, action.payload],
    });
  }
};
const setCanvas = (state, action) => {
  return updateObject(state, {
    canvas: action.payload,
  });
};
const loadTemplates = (state, action) => {
  return updateObject(state, {
    templateList: action.payload,
    template: action.payload[0],
  });
};
const updateTemplateList = (state, action) => {
  let index = state.templateList.findIndex((id) => id === action.payload.id);
  if (!index) {
    return;
  }
  return updateObject(state, {
    templateList: [
      ...state.templateList.slice(0, index),
      {
        ...action.payload,
      },
      ...state.templateList.slice(index + 1),
    ],
  });
};
const setActiveTemplate = (state, action) => {
  return updateObject(state, {
    template: action.payload,
  });
};
const setActiveImage = (state, action) => {
  return updateObject(state, {
    activeObj: action.payload,
  });
};
const clearTemplate = (state, action) => {
  return updateObject(state, {
    template: null,
  });
};
const textChanged = (state, action) => {
  return updateObject(state, {
    textChanged: state.textChanged + 1,
  });
};
const endEdit = (state, action) => {
  return updateObject(state, {
    activeObj: null,
  });
};
const setScale = (state, action) => {
  return updateObject(state, {
    scale: action.payload,
  });
};
const setZoom = (state, action) => {
  return updateObject(state, {
    zoom: action.payload,
  });
};
const ppiAlert = (state, action) => {
  let index = state.ppiAlert.findIndex((f) => f.id === action.payload.id);
  if (index !== -1) {
    // page exists, update page
    return updateObject(state, {
      ppiAlert: [
        ...state.ppiAlert.slice(0, index),
        {
          ...action.payload,
        },
        ...state.ppiAlert.slice(index + 1),
      ],
    });
  } else {
    // ppi alert for object with id doesn't exist, create it
    return updateObject(state, {
      ppiAlert: [...state.ppiAlert, action.payload],
    });
  }
};
const resetPpiAlert = (state, action) => {
  return updateObject(state, {
    ppiAlert: state.ppiAlert.filter((f) => f.id !== action.payload),
  });
};
const selectMockup = (state, action) => {
  return updateObject(state, {
    mockupSelected: action.payload,
  });
};
const loadMockups = (state, action) => {
  return updateObject(state, {
    mockups: action.payload,
  });
};

const loadColor = (state, action) => {
  return updateObject(state, {
    colors: action.payload
  })
}

// Clears all full mockup previews related to specified template
const clearFullMockup = (state, action) => {
  return updateObject(state, {
    mockupFullList: state.mockupFullList.filter(
      (f) => !action.payload.idList.includes(f.mockupsNr)
    ),
  });
};
// Clears all thumbnail mockup previews related to specified template
const clearThumbMockup = (state, action) => {
  return updateObject(state, {
    mockupThumbsList: state.mockupThumbsList.filter(
      (f) => !action.payload.idList.includes(f.mockupsNr)
    ),
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_MOCKUPS:
      return loadMockups(state, action);
    case actionTypes.MOCKUP_SELECTED:
      return selectMockup(state, action);
    case actionTypes.ADD_UPDATE_MOCKUP_THUMBNAIL:
      return addUpdateMockupThumbnail(state, action);
    case actionTypes.ADD_UPDATE_MOCKUP_FULL:
      return addUpdateMockupFull(state, action);
    case actionTypes.PPI_ALERT:
      return ppiAlert(state, action);
    case actionTypes.RESET_PPI_ALERT:
      return resetPpiAlert(state, action);
    case actionTypes.SET_ZOOM:
      return setZoom(state, action);
    case actionTypes.SET_SCALE:
      return setScale(state, action);
    case actionTypes.END_EDIT:
      return endEdit(state, action);
    case actionTypes.ADD_OR_UPDATE_PAGE:
      return addOrUpdatePage(state, action);
    case actionTypes.SET_CANVAS:
      return setCanvas(state, action);
    case actionTypes.LOAD_COLOR:
      return loadColor(state, action);
    case actionTypes.LOAD_TEMPLATES:
      return loadTemplates(state, action);
    case actionTypes.UPDATE_TEMPLATES:
      return updateTemplateList(state, action);
    case actionTypes.SET_ACTIVE_TEMPLATE:
      return setActiveTemplate(state, action);
    case actionTypes.SET_ACTIVE_IMAGE:
      return setActiveImage(state, action);
    case actionTypes.CLEAR_TEMPLATE:
      return clearTemplate(state, action);
    case actionTypes.TEXT_CHANGED:
      return textChanged(state, action);
    case actionTypes.CLEAR_FULL_MOCKUP:
      return clearFullMockup(state, action);
    case actionTypes.CLEAR_THUMB_MOCKUP:
      return clearThumbMockup(state, action);
    default:
      return state;
  }
};

export default reducer;
