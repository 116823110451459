import React from "react";
import { useDispatch } from "react-redux";
import { toggleProperty } from "../../../store/actions/image";
import { useSelector } from "react-redux";
import {
  TypeUnderline,
} from 'react-bootstrap-icons';

// Alignment option buttons
const UnderlineButton = ({ element }) => {
  const dispatch = useDispatch();
  const canvas = useSelector(({ image }) => image.canvas);
  const changeProperty = (val) => {
    dispatch(toggleProperty(element.id, "underline"));
  };
  const getValue = (id) => {
    const item = canvas.getObjects().find((f) => f.id === id);
    if (item) {
      return item.underline;
    } else {
      return null;
    }
  };
  return (
    <button
      className={`btn btn-icon btn-icon-sm btn-icon-${
        getValue(element.id) ? "-active" : ""
      }`}
      onClick={() => changeProperty()}
    >
      <TypeUnderline />
    </button>
  );
};

export default UnderlineButton;
