import * as actionTypes from "../../store/actions/actionTypes";
import { fabric } from "fabric";
import {
  mm_to_inch,
  DESIGN_VIEW,
  ZOOM_FACTOR,
} from "../../constants/constants";
import { clearMockupPreviews } from "./mockups";
import Store from "../store";
import axios from "axios";
import { closeModal } from "./app";
import { v4 as uuidv4 } from "uuid";

/**
 *
 * @param {fabric} canvas temporary canvas to render each page svg and png
 * @param {object} page page object {json, clip, id, name}
 */
const preparePage = (canvas, page, colors) => {
  return new Promise(function (resolve, reject) {
    canvas.loadFromJSON(page.json, function () {
      try {
        canvas.backgroundColor = colors[0];
        canvas.renderAll();
        const clipArea = page.clip;
        const svg = canvas
          .toSVG()
          .replace(/width="\d*\.?\d*"/, `width="${clipArea.widthMM}mm"`)
          .replace(/height="\d*\.?\d*"/, `height="${clipArea.heightMM}mm"`)
          .replace(
            /viewBox=".{1,}"/,
            `viewBox="${clipArea.x} ${clipArea.y} ${clipArea.width} ${clipArea.height}"`
          );
        const png = canvas.toDataURL({
          format: "png",
          top: clipArea.y,
          left: clipArea.x,
          width: clipArea.width,
          height: clipArea.height,
        });
        resolve({
          id: page.id,
          name: page.name,
          layer_on_top: page.layer_on_top,
          svg: svg,
          png: png,
        });
      } catch (error) {
        reject(error);
      }
    });
  });
};
/**
 *
 * @param {array} data pages mapped to svg, png [{svg, png, id, name}]
 */
const postOrder = (data, domain, apiKey, templateId, mockupThumbs) => {
  axios
    .post(
      `${domain}/api/templates/orders`,
      {
        data: {
          pages: data,
          template: templateId,
          date: new Date(),
          previewMockup: mockupThumbs,
        },
      },
      {
        headers: {
          Authorization: `${apiKey}`,
        },
      }
    )
    .then((res) => {
      Store.getStore().dispatch(closeModal());
      if (
        res.status === 200 &&
        res.data &&
        res.data.message &&
        res.data.message.orderId
      ) {
        Store.getStore().dispatch({ type: actionTypes.SUBMIT_ORDER_SUCCESS });
        const successCallback = Store.getSuccessCallback();
        if (successCallback !== null) {
          successCallback({
            msg: "success",
            data: {
              pages: data,
              template: templateId,
              date: new Date(),
              previewMockup: mockupThumbs,
              orderId: res.data.message.orderId,
            },
          });
        }
      } else {
        throw new Error("failed to submit order.")
      }
    })
    .catch((err) => {
      Store.getStore().dispatch({ type: actionTypes.SUBMIT_ORDER_FAILED });
      Store.getStore().dispatch(closeModal());
      console.error(err);
      const failCallback = Store.getFailCallback();
      if (failCallback !== null) {
        failCallback({ msg: "error", res: err });
      }
    });
};
export const saveCanvasState = () => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  if (canvas && template && template.id) {
    dispatch({
      type: actionTypes.ADD_OR_UPDATE_PAGE,
      payload: {
        json: canvas.toJSON(["id", "pageId", "title"]),
        id: template.id,
      },
    });
    // dispatch(clearFullMockup(template.id));
    dispatch(clearMockupPreviews(template.id));
  }
};
/**
 * map pages to png, svg and post
 */
export const submitOrder = () => (dispatch, getState) => {
  const { canvas, pages, template, mockupThumbsList, mockups, colors } =
    getState().image;
  const { domain, apiKey, templateId, indexSelected } = getState().app;
  dispatch({ type: actionTypes.START_SUBMIT_ORDER });
  if (!pages) {
    return;
  }
  // mockup thumb list
  let mockupThumbs = [];
  if (
    mockupThumbsList &&
    mockupThumbsList.length > 0
  ) {
    mockupThumbs = mockupThumbsList
  }
  const promises = [];
  const c = new fabric.Canvas();
  let currentPage = null;
  // if in design view, save current page
  if (indexSelected === DESIGN_VIEW) {
    currentPage = canvas.toJSON(["id", "pageId", "title"]);
  }
  pages.forEach((page) => {
    if (!page.json) {
      return;
    }
    // if in design view, save current canvas and use json instead of old json
    if (currentPage && page.id === template.id) {
      promises.push(
        preparePage(
          c,
          {
            json: currentPage,
            id: page.id,
            name: page.name,
            clip: page.clip,
          },
          colors
        )
      );
    } else {
      promises.push(preparePage(c, page, colors));
    }
  });
  Promise.all(promises).then(
    function () {
      postOrder(arguments[0], domain, apiKey, templateId, mockupThumbs);
    },
    function (err) {
      const failCallback = Store.getFailCallback();
      Store.getStore().dispatch(closeModal());
      console.error(err);
      if (failCallback !== null) {
        failCallback({ msg: "error", res: err });
      }
    }
  );
};

// FabricJS
const addMaskAndRender = (
  canvas,
  clipArea,
  objectToAdd,
  index,
  dispatch,
  templateId
) => {
  objectToAdd.id = index;
  // Add clipping mask with absolute positioning to the image
  const negative = new fabric.Rect({
    top: clipArea.y,
    left: clipArea.x,
    width: clipArea.width,
    height: clipArea.height,
    absolutePositioned: true,
    selectable: false,
  });
  // When clicking on an image, update redux respectively
  objectToAdd.on("selected", function (e) {
    const obj = canvas.getActiveObject();
    if (obj)
      dispatch({
        type: actionTypes.SET_ACTIVE_IMAGE,
        payload: { id: obj.id, type: obj.type },
      });
  });
  objectToAdd.left =
    clipArea.x +
    clipArea.width / 2 -
    (objectToAdd.width * objectToAdd.scaleX) / 2;
  objectToAdd.top =
    clipArea.y +
    clipArea.height / 2 -
    (objectToAdd.height * objectToAdd.scaleY) / 2;
  objectToAdd.setCoords();
  // set the negative mask
  objectToAdd.set({
    clipPath: negative,
  });
  objectToAdd.set({
    pageId: templateId,
  });
  objectToAdd.set({
    id: uuidv4(),
  });
  objectToAdd.set({
    cornerSize: 16,
  });
  // center and add to canvas
  canvas.add(objectToAdd);
  canvas.renderAll();
  dispatch(saveCanvasState());
};
// Image actions
// loads template image
export const loadTemplateImage = () => (dispatch, getState) => {
  const { canvas, template, colors } = getState().image;
  const loadBackground = (originalImg, options) => {
    if (!originalImg) {
      return;
    }
    let img = null;
    if (isSvg === true) {
      try {
        const colorLayer = originalImg.find((f) => f.id === "color");
        if (colorLayer && colors && colors[0]) {
          colorLayer.fill = colors[0];
        }
        img = fabric.util.groupSVGElements(originalImg, options);
      } catch (e) {
        console.error(e);
      }
    } else {
      img = new fabric.Image(originalImg);
    }
    let longestSide = img.width > img.height ? img.width : img.height;
    img.scale(2000 / longestSide);
    img.excludeFromExport = true;
    canvas.setBackgroundImage(
      img,
      function () {
        canvas.renderAll.bind(canvas);
      },
      {}
    );
    // scale all templates to XXX x 2000 px, save factor scaled by and the clip area for this template in the state
    canvas.setHeight((2000 / longestSide) * img.height);
    canvas.setWidth((2000 / longestSide) * img.width);
    canvas.zoomToPoint(
      new fabric.Point(canvas.width / 2, canvas.height / 2),
      ZOOM_FACTOR
    );

    // update scale state
    dispatch({ type: actionTypes.SET_SCALE, payload: 2000 / longestSide });
    const clip = {
      x: ((2000 / longestSide) * img.width * template.clip.position.left) / 100,
      y: ((2000 / longestSide) * img.height * template.clip.position.top) / 100,
      width:
        ((2000 / longestSide) * img.width * template.clip.size.widthP) / 100,
      height:
        ((2000 / longestSide) * img.height * template.clip.size.heightP) / 100,
      widthMM: template.clip.size.width,
      heightMM: template.clip.size.height,
    };
    // set active template cliparea
    dispatch({
      type: actionTypes.ADD_OR_UPDATE_PAGE,
      payload: {
        id: template.id,
        json: canvas.toJSON(["id", "pageId", "title"]),
        name: template.name,
        clip: clip,
      },
    });
    const clipRect = new fabric.Rect({
      top: clip.y,
      left: clip.x,
      width: clip.width,
      height: clip.height,
      selectable: false,
      excludeFromExport: true,
      fill: "rgba(0,0,0,0)",
      stroke: "rgba(33, 75, 199, 1)",
      cornerColor: "rgba(33, 75, 199, 1)",
      cornerSize: 30,
      transparentCorners: false,
      strokeWidth: 4,
    });
    if (template.layer_on_top === true) {
      const group = new fabric.Group([clipRect, img]);
      canvas.controlsAboveOverlay = true;
      // render the clipping rect from clipping box and transparent background png
      canvas.setOverlayImage(group, canvas.renderAll.bind(canvas));
    } else {
      // render the clipping rect from clipping box
      canvas.setOverlayImage(clipRect, canvas.renderAll.bind(canvas));
    }
    dispatch({ type: actionTypes.TEXT_CHANGED });
  };
  const isSvg = template.type === "svg";
  if (isSvg) {
    fabric.loadSVGFromString(template.ref, function (img, options) {
      loadBackground(img, options, isSvg);
    });
  } else {
    fabric.util.loadImage(template.ref, function (img) {
      loadBackground(img, null, isSvg);
    });
  }
};
// Load image
export const loadImage = (imgObj, name, type) => (dispatch, getState) => {
  try {
    const { canvas, template, pages, index } = getState().image;
    const page = pages.find((f) => f.id === template.id);
    const clipArea = page ? page.clip : null;
    if (!template || !clipArea) {
      return;
    }
    // render image
    if (imgObj) {
      fabric.util.loadImage(imgObj.src, function (img) {
        var oImg = new fabric.Image(img);
        // scale image to fit by width into clip area
        oImg.scaleX = clipArea.width / oImg.width;
        oImg.scaleY = clipArea.width / oImg.width;
        oImg.title = name;
        addMaskAndRender(canvas, clipArea, oImg, index, dispatch, template.id);

        const ppiX =
          oImg.width /
          (template.clip.size.width *
            mm_to_inch *
            ((oImg.width * oImg.scaleX) / clipArea.width));
        const ppiY =
          oImg.height /
          (template.clip.size.height *
            mm_to_inch *
            ((oImg.height * oImg.scaleY) / clipArea.height));
        // if ppi is less than 300, toast alert
        if (ppiX < 300 || ppiY < 300) {
          dispatch({
            type: actionTypes.PPI_ALERT,
            payload: {
              ppiX: ppiX,
              ppiY: ppiY,
              id: oImg.id,
            },
          });
        }
        oImg.on("scaled", (e) => ppiScaleListener(e, template, clipArea));
      });
    } else {
      const textStaging = new fabric.IText("CLICK TO EDIT", {
        fontFamily: "Roboto",
        left: 400,
        top: 400,
        fontWeight: "400",
        fontSize: 60,
      });
      textStaging.on("modified", function (e) {
        dispatch({
          type: actionTypes.TEXT_CHANGED,
        });
      });
      addMaskAndRender(
        canvas,
        clipArea,
        textStaging,
        index,
        dispatch,
        template.id
      );
    }
  } catch (e) {
    console.error(e);
  }
};
// delete image
export const deleteImage = (id) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  const imgObj = canvas.getObjects().find((f) => f.id === id);
  canvas.remove(imgObj);
  dispatch(saveCanvasState());
  dispatch({
    type: actionTypes.END_EDIT,
  });
  dispatch({ type: actionTypes.RESET_PPI_ALERT, payload: id });
};
// ppi scale listener
export const ppiScaleListener = (e, template, clipArea) => {
  const ppiX =
    e.target.width /
    (template.clip.size.width *
      mm_to_inch *
      ((e.target.width * e.target.scaleX) / clipArea.width));
  const ppiY =
    e.target.height /
    (template.clip.size.height *
      mm_to_inch *
      ((e.target.height * e.target.scaleY) / clipArea.height));
  // if ppi is less than 300, toast alert
  if (ppiX < 300 || ppiY < 300) {
    Store.getStore().dispatch({
      type: actionTypes.PPI_ALERT,
      payload: {
        ppiX: ppiX,
        ppiY: ppiY,
        id: e.target.id,
      },
    });
  } else {
    Store.getStore().dispatch({
      type: actionTypes.RESET_PPI_ALERT,
      payload: e.target.id,
    });
  }
};
// duplicate image
export const duplicateImage = (id) => (dispatch, getState) => {
  const { canvas, template, pages } = getState().image;
  const page = pages.find((f) => f.id === template.id);
  const clipArea = page ? page.clip : null;
  const objects = canvas.getObjects();
  const obj = objects.find((f) => f.id === id);
  if (obj) {
    obj.clone(function (clone) {
      clone.set({
        left: clone.left + 10,
        top: clone.top + 10,
        id: uuidv4(),
        pageId: obj.pageId,
        title: obj.title ? obj.title : undefined,
        cornerSize: 32,
      });
      clone.on("selected", function (e) {
        const obj = canvas.getActiveObject();
        if (obj)
          dispatch({
            type: actionTypes.SET_ACTIVE_IMAGE,
            payload: { id: obj.id, type: obj.type },
          });
      });
      if (clone.type === "image") {
        const ppiX =
          clone.width /
          (template.clip.size.width *
            mm_to_inch *
            ((clone.width * clone.scaleX) / clipArea.width));
        const ppiY =
          clone.height /
          (template.clip.size.height *
            mm_to_inch *
            ((clone.height * clone.scaleY) / clipArea.height));
        // if ppi is less than 300, toast alert
        if (ppiX < 300 || ppiY < 300) {
          dispatch({
            type: actionTypes.PPI_ALERT,
            payload: {
              ppiX: ppiX,
              ppiY: ppiY,
              id: clone.id,
            },
          });
        }
        clone.on("scaled", (e) => ppiScaleListener(e, template, clipArea));
      }
      canvas.add(clone);
      dispatch(saveCanvasState());
    });
  }
};
// Add or update page
export const AddOrUpdatePage = (id) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  dispatch({
    type: actionTypes.ADD_OR_UPDATE_PAGE,
    payload: {
      json: canvas.toJSON(["id", "pageId", "title"]),
      id: template.id,
      name: template.name,
    },
  });
  dispatch(setActiveTemplate(id));
  dispatch(setZoom(1));
};
export const SavePage = () => async (dispatch, getState) => {
  const { canvas, template } = getState().image;
  if (!canvas || !template) {
    return;
  }
  await dispatch({
    type: actionTypes.ADD_OR_UPDATE_PAGE,
    payload: {
      json: canvas.toJSON(["id", "pageId", "title"]),
      id: template.id,
      name: template.name,
    },
  });
};
// Set active template
export const setActiveTemplate = (id) => (dispatch, getState) => {
  const { templateList } = getState().image;
  const activeTemplate = templateList.find((p) => p.id === id);
  dispatch({
    type: actionTypes.SET_ACTIVE_TEMPLATE,
    payload: activeTemplate,
  });
};
// set Font size
export const setFontSize = (id, val) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set("fontSize", val);
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// change Font
export const setFont = (id, val) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set("fontFamily", val);
  canvas.renderAll();
  dispatch(saveCanvasState());
  // dispatch({ type: actionTypes.TEXT_CHANGED });
};
// change alignment
export const setAlignment = (id, val) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set("textAlign", val);
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// change font style
export const setFontStyle = (id, val) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set("fontStyle", val);
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// toggle property
export const toggleProperty = (id, property) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  let obj = canvas.getObjects().find((f) => f.id === id);
  if (!obj) {
    return;
  }
  obj.set(property, !obj[property]);
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// change Opacity
export const setOpacity = (id, val) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set({ opacity: val });
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// change line height
export const setLineHeight = (id, val) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set({ lineHeight: val });
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// change spacing
export const setSpacing = (id, val) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set({ charSpacing: val });
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// change property
export const setProperty = (id, val, property) => (dispatch, getState) => {
  const { canvas } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set({ [property]: val });
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// change font weight
export const setFontWeight = (id, val) => (dispatch, getState) => {
  const { canvas, template } = getState().image;
  canvas
    .getObjects()
    .find((f) => f.id === id)
    .set({ fontWeight: val });
  canvas.renderAll();
  // dispatch({ type: actionTypes.TEXT_CHANGED });
  dispatch(saveCanvasState());
};
// set zoom
export const setZoom = (zoom) => (dispatch, getState) => {
  const { canvas } = getState().image;
  canvas.zoomToPoint(
    new fabric.Point(canvas.width / 2, canvas.height / 2),
    zoom * ZOOM_FACTOR
  );
  dispatch({ type: actionTypes.SET_ZOOM, payload: zoom });
};
// reset zoom
export const resetZoom = () => (dispatch, getState) => {
  const { canvas } = getState().image;
  canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
  dispatch({ type: actionTypes.SET_ZOOM, payload: 1 });
};

/**
 * Save colors array to memory
 * @param {Array} colors array of hex colors
 * @returns undefined
 */
export const loadColors = (colors) => (dispatch, getState) => {
  dispatch({ type: actionTypes.LOAD_COLOR, payload: colors });
};
