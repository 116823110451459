import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./store/store";
import { loadFonts } from "./helpers/helpers"

// Mediamodifier POD editor side launching scripts

export const init = (config) => {
  const el = document.getElementById(config.defaultContainer);
  const settings = {
    defaultContainer: config.defaultContainer,
  };
  // load fonts
  if (!config.adminMode) {
    loadFonts()
  }
  // init redux store
  const store = new Store(config);
  ReactDOM.render(
    <Provider store={store}>
      <App config={settings} />
    </Provider>,
    el
  );
};
