import React from "react";
import AlignmentButton from "../../components/layer/editor/AlignmentButton";
import OpacityOption from "./editor/sliders/OpacityOption";
import LineHeightOption from "./editor/sliders/LineHeightOption";
import SpacingOption from "./editor/sliders/SpacingOption";
import SelectOption from "./editor/select/SelectOption";
import FontFamilyHook from "./editor/select/FontFamilyHook";
import FontSizeHook from "./editor/select/FontSizeHook";
import FontWeightHook from "./editor/select/FontWeightHook";
import ItalicButton from "./editor/ItalicButton";
import UnderlineButton from "./editor/UnderlineButton";
import BoolAttributeButton from "./editor/BoolAttributeButton";
import ColorPicker from "./editor/ColorPicker";

// Text edit options
const EditTextOptions = ({ element }) => {
  return (
    <div>
      <div className="row mb-2">
        <div className="col-12">
          <SelectOption {...FontFamilyHook({ element })} />
        </div>
      </div>
      <div className="row">
        <div className="col-7 pr-1">
          <SelectOption {...FontWeightHook({ element })} />
        </div>
        <div className="col-5 pl-1">
          <SelectOption {...FontSizeHook({ element })} />
        </div>
      </div>

      <div className="pt-2 pb-1">
        <AlignmentButton element={element} align="left" />
        <AlignmentButton element={element} align="center" />
        <AlignmentButton element={element} align="right" />
        <AlignmentButton element={element} align="justify" />
        <ItalicButton element={element} />
        <UnderlineButton element={element} />
        <BoolAttributeButton element={element} att="overline" />
        <BoolAttributeButton element={element} att="linethrough" />
        <ColorPicker element={element} />
      </div>
      <div className="row">
        <div className="col-12">
          <OpacityOption
            element={element}
            className="row d-flex align-items-center mt-2"
          />
          <LineHeightOption
            element={element}
            className="row d-flex align-items-center mt-2"
          />
          <SpacingOption
            element={element}
            className="row d-flex align-items-center mt-2"
          />
        </div>
      </div>
    </div>
  );
};

export default EditTextOptions;
