import { useSelector, useDispatch } from "react-redux";
import { saveCanvasState } from "../../../store/actions/image";
import Layer from "../../../components/layer/Layer";
import NewImageLayer from "../../../components/layer/NewImageLayer";
import NewTextLayer from "../../../components/layer/NewTextLayer";
import { sortableContainer, sortableElement } from "react-sortable-hoc";

const SortableContainer = sortableContainer(({ children }) => (
  <div>{children}</div>
));
const SortableElement = sortableElement(({ element }) => (
  <Layer key={element.id} el={element} />
));

// pod list editor layer view
const LayerListView = () => {
  const canvas = useSelector(({ image }) => image.canvas);
  const template = useSelector(({ image }) => image.template);
  const pageObjects = useSelector(({ image }) => {
    if (template && template.id) {
      const page = image.pages.find((p) => p.id === template.id);
      if (page && page.json && page.json.objects && page.json.objects.length) {
        return page.json.objects;
      }
      return [];
    } else {
      return [];
    }
  });
  const dispatch = useDispatch();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    canvas.getObjects()[oldIndex].moveTo(newIndex);
    dispatch(saveCanvasState());
  };
  const handleClick = (e) => {
    if (
      e.target.getAttribute("id") &&
      e.target.getAttribute("id").includes("handler")
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <div className="mm-pod-sidebar-body">
      <NewTextLayer />
      <NewImageLayer />
      <div className="my-4"></div>
      <SortableContainer
        shouldCancelStart={(e) => handleClick(e)}
        axis="y"
        lockAxis={"y"}
        onSortEnd={onSortEnd}
        distance={1}
      >
        {pageObjects && pageObjects.length > 0
          ? pageObjects
              .filter((f) => f.pageId === template.id)
              .reverse()
              .map((element, index) => (
                <SortableElement
                  index={index}
                  key={`se-${element.id}`}
                  element={element}
                />
              ))
          : ""}
      </SortableContainer>
    </div>
  );
};

export default LayerListView;
