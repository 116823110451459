// Ppi alert sub element
const PpiAlert = () => {
  const onClick = (e) => {
    var x = e.buttons;
    switch (x) {
      case 1:
        window.open("https://mediamodifier.com/blog/print-guide", "_blank");
        break;
      case 4:
        window.open("https://mediamodifier.com/blog/print-guide", "_blank");
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <div className="d-inline-block h-100">
        <p className="font-weight-bold mt-2 text-danger">
          Low resolution artwork detected.
        </p>
        <p className="mt-0 pt-0 mm-pod-text text-justify">
          The uploaded file has a low resolution and it cannot be printed.
          Please upload artwork with a higher resolution or scale it down.
        </p>
        <p className="mt-0 pt-0 mm-pod-text text-justify">
          <a href="#" onMouseDown={onClick}>
            See the guide
          </a>{" "}
          for more info.
        </p>
      </div>
    </div>
  );
};
export default PpiAlert;
