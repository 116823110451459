import { FileDeleteIcon } from "../../../../components/icons/Icons";
import { useDispatch } from "react-redux";
import { UploadTemplateStyle } from "../../../styles/styles";
import * as actionTypes from "../../../../store/actions/actionTypes";
import { allowed_file_types } from "../../../../constants/constants";

// Layer list view element for admin
const EditPage = ({ el }) => {
  const dispatch = useDispatch();
  const onChange = (val) => {
    dispatch({ type: actionTypes.UPDATE_PAGE, payload: { ...el, name: val } });
  };

  const onChangeLayerOnTop = (val) => {
    dispatch({ type: actionTypes.UPDATE_PAGE_LAYER_ON_TOP, payload: { ...el, layer_on_top: val} });
  };
  const loadFile = (e) => {
    if (e.target.files[0].size > 52428800) {
      alert("Maximum file size exceeded!");
      return;
    }
    var reader = new FileReader();
    reader.onload = (event) => {
      const res = event.target.result;
      const name = e.target.files[0].name;
      const fileType = name.substr(name.lastIndexOf(".") + 1);
      var svg = null;
      var img = null;
      if (fileType === "svg") {
        svg = atob(res.slice(res.indexOf(";base64,") + 8));
      } else {
        img = event.target.result;
      }
      dispatch({
        type: actionTypes.UPDATE_PAGE,
        payload: {
          ...el,
          ref: fileType === "svg" ? svg : img,
          file: name,
          type: fileType,
        },
      });
      dispatch({
        type: actionTypes.PAGE_SELECTED,
        payload: {
          ...el,
          ref: fileType === "svg" ? svg : img,
          file: name,
          type: fileType,
        },
      });
    };
    if (e.target.files[0]) reader.readAsDataURL(e.target.files[0]);
  };
  const deleteFile = () => {
    dispatch({
      type: actionTypes.UPDATE_PAGE,
      payload: { ...el, file: "", ref: "" },
    });
  };
  return (
    <div className={"position-relative mt-2"}>
      <input 
        className="float-left"
        type="checkbox"
        style={{width: '20px', height: '20px'}}
        onChange={(e) => onChangeLayerOnTop(e.target.checked)}
        checked={el.layer_on_top || false}
      />
      <label
        className="float-left text-muted ml-2"
      >Layer on top</label>
      <input
        className="w-100 mb-2 form-control"
        type="text"
        value={el.name}
        placeholder={"Enter page name"}
        onChange={(e) => onChange(e.target.value)}
      />
      {el.file ? (
        <>
          <label className="form-control d-inline-block">{el.file}</label>
          <label
            onClick={deleteFile}
            style={{
              position: "absolute",
              right: "6px",
              marginTop: "6px",
            }}
          >
            <FileDeleteIcon />
          </label>
        </>
      ) : (
        <div>
          <div className="position-relative">
            <input
              className="form-control"
              type="file"
              accept={allowed_file_types}
              onChange={(e) => loadFile(e)}
              title="Upload image"
              style={UploadTemplateStyle}
            />
            <button className="btn btn-secondary btn-sm btn-block" style={{ pointerEvents: 'none' }}>
              Upload base image
            </button>
          </div>
          <div>
            <small className="form-help">
              Recommended size 3543 x 4008 px
            </small>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditPage;
