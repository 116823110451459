import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { closeModal } from "../../../store/actions/app";

// Flow control buttons - cancel, continue
const AdminFlowControl = () => {
  const dispatch = useDispatch();
  const pages = useSelector(({ admin }) => admin.pages);
  const domain = useSelector(({ app }) => app.domain);
  const apiKey = useSelector(({ app }) => app.apiKey);
  const templateId = useSelector(({ app }) => app.templateId);
  /**
   * check if clipping area added to all views
   * returns true if ready to save, false otherwise
   * @returns bool
   */
  const viewsReady = () => {
    if (pages) {
      if (pages.find((f) => f.clip.type === undefined)) {
        return true;
      } else {
        return false;
      }
    }
  };
  // save template
  const SaveTemplate = () => {
    axios
      .post(
        `${domain}/api/templates/${templateId}`,
        { pages: pages },
        {
          headers: {
            Authorization: `${apiKey}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          close();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // close modal
  const close = () => {
    dispatch(closeModal());
  };
  return (
    <footer className="mm-pod-sidebar-footer">
      <div className="row mx-n1">
        <div className="col-auto px-1">
          <button type="button" className="btn btn-link" onClick={close}>
            Cancel
          </button>
        </div>
        <div className="col">
          <button
            className={`btn btn-secondary btn-block`}
            disabled={ viewsReady() }
            onClick={SaveTemplate}
          >
            Continue
          </button>
        </div>
      </div>
    </footer>
  );
};

export default AdminFlowControl;
