import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import Layer from "../../../components/admin/layer/Layer";
import NewLayer from "../../../components/admin/layer/NewLayer";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

const SortableContainer = sortableContainer(({ children }) => (
  <div>{children}</div>
));
const SortableElement = sortableElement(({ element, ind }) => {
  //   index not passed, need to rename
  return <Layer key={element.id} el={element} index={ind + 1} />;
});

// pod list editor layer view
const AdminLayerListView = () => {
  const pages = useSelector(({ admin }) => admin.pages);
  const canvas = useSelector(({ image }) => image.canvas);
  const dispatch = useDispatch();
  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch({
      type: actionTypes.SET_PAGES,
      payload: arrayMove([...pages], oldIndex, newIndex),
    });
  };
  const handleClick = (e) => {
    if (
      e.target.getAttribute("id") &&
      e.target.getAttribute("id").includes("handler")
    ) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (!canvas || !pages || pages.length === 0) {
      return;
    }
    canvas.getObjects().forEach((f) => {
      f.moveTo(pages.length - pages.findIndex((i) => i.id === f.id) - 1);
    });
  }, [pages]);
  return (
    <div className="mm-pod-sidebar-body">
      <NewLayer />
      <SortableContainer
        shouldCancelStart={(e) => handleClick(e)}
        axis="y"
        lockAxis={"y"}
        onSortEnd={onSortEnd}
        distance={1}
      >
        {pages && pages.length > 0
          ? pages.map((element, index) => (
              <SortableElement
                ind={index}
                index={index}
                key={`se-${element.id}`}
                element={element}
              />
            ))
          : ""}
      </SortableContainer>
    </div>
  );
};

export default AdminLayerListView;
